import * as React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'react-jss';

interface IProps {
  theme: Record<any, any>;
  children: React.ReactElement;
}
const JssThemeProvider = (props: IProps) => {
  const { theme, children } = props;
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.uiTheme,
  };
};

export default connect(mapStateToProps)(JssThemeProvider);
