import * as React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import authHelper from '@auth/auth-helper';
import { getDropoffDashboardRoute, getLoginRoute } from '@routing/routing-helper';

const DefaultPage = () => {
  const authenticated = authHelper.isAuthenticated();
  if (authenticated) {
    return (
      <Redirect to={{ pathname: getDropoffDashboardRoute() }} />
    );
  }
  return (
    <Redirect to={{ pathname: getLoginRoute() }} />
  );
};

const DefaultUrl = withRouter(DefaultPage);
export default DefaultUrl;
