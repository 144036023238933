import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loginStyles } from '@login/sign-in.styles';
import { login } from '@network/common.api';
import { StylesProps } from '@theme/jss-types';
import {
  getDropoffDashboardRoute,
} from '@routing/routing-helper';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Collapse,
} from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import { HocOptions } from '@common/generic-hoc.types';
import GenericHoc from '@common/generic-hoc';
import { LOGO_URL, useOnlyForLocalTestingOrgId } from '@src/globals';
import { faqAndAnswers } from '@tabularDropoff/masterData';
import { decryptWithSessionKey } from '@src/library/utils';

interface LoginProps extends StylesProps<typeof loginStyles> {}

const SignIn = (props: LoginProps): React.ReactElement => {
  const history = useHistory();
  const { classes } = props;
  const [disableButton, setDisableButton] = useState(false);

  const saveLoginResult = async (result: any) => {
    const storage = window.localStorage;
    const hub_code = result.data.profile.facilityType === 'BR' ? result.data.profile.counterCode
      : result.data.profile.facilityCode;
    storage.setItem('source', result.data.profile.facilityType);
    storage.setItem('organisationId', useOnlyForLocalTestingOrgId);
    storage.setItem('userId', result.data.profile.usercode);
    storage.setItem('hubCode', hub_code);
    storage.setItem('authToken', result.data.token);
    storage.setItem('logoUrl', LOGO_URL);
    storage.setItem('username', result.data.profile.username);
    storage.setItem('allowCashPaymentForDropOff', result?.data?.config?.allowCashPaymentForDropOff);
  };

  const handlePudoLogin = async () => {
    const urlParams = new URLSearchParams(history.location.search);
    const unencryptedParams: any = urlParams.get('key');
    const encryptedParams: any = urlParams.get('ekey');
    if (!unencryptedParams && !encryptedParams) {
      return;
    }
    let loginInfoStr = '';
    if (encryptedParams) {
      loginInfoStr = await decryptWithSessionKey(encryptedParams);
    } else {
      loginInfoStr = Buffer.from(unencryptedParams, 'base64').toString();
    }

    const loginInfo = JSON.parse(loginInfoStr);

    saveLoginResult({
      data: {
        profile: {
          username: loginInfo.username,
          facilityType: loginInfo.facilityType,
          facilityCode: loginInfo.facilityCode,
          counterCode: loginInfo.counterCode,
        },
        token: loginInfo.token,
      },
    });
    history.push(getDropoffDashboardRoute());
  };

  React.useEffect(() => {
    handlePudoLogin();
  }, []);

  const onFinish = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    setDisableButton(true);
    const body = {
      username,
      password,
    };
    const result = await login(body);

    setDisableButton(false);
    if (!result?.isSuccess) {
      message.error(result?.errorMessage || 'Something went wrong! Please try again');
    } else {
      saveLoginResult(result);
      history.push(getDropoffDashboardRoute());
    }
  };

  const renderPassword = () => {
    return (
      <Form.Item
        className={classes.emailLabel}
        name="password"
        colon={false}
        rules={[{
          required: true,
          message: 'This field can’t be empty.',
        }]}
      >
        <Input.Password
          className={classes.emailInput}
          placeholder="Enter Your Password"
        />
      </Form.Item>
    );
  };

  const renderUsername = () => {
    return (
      <Form.Item
        className={classes.emailLabel}
        name="username"
        colon={false}
        rules={[{
          required: true,
          message: 'Username is required',
        }]}
      >
        <Input
          className={classes.emailInput}
          autoComplete="off"
          placeholder="Enter Username "
        />
      </Form.Item>
    );
  };

  const renderLoginButton = () => {
    return (
      <Form.Item
        wrapperCol={{ span: 24 }}
        style={{
          textAlign: 'left',
          marginBottom: '32px',
        }}
      >
        <Button
          className={classes.continueButton}
          style={{ margin: '0 16px 16px 0' }}
          type="primary"
          htmlType="submit"
          loading={disableButton}
        >
          Sign In
        </Button>
      </Form.Item>
    );
  };

  const renderPageHeading = () => {
    return (
      <label className={classes.loginPageHeading}>MyDTDC DropOff Portal</label>
    );
  };

  const renderSignInForm = () => {
    return (
      <Form
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
      >
        {renderUsername()}
        {renderPassword()}
        {renderLoginButton()}
      </Form>
    );
  };

  const [isFaqModalOpenBeforeLogin, setIsFaqModalOpenBeforeLogin] = React.useState(false);

  const showFaqModalBeforeLogin = () => {
    setIsFaqModalOpenBeforeLogin(true);
  };

  const handleFaqOkBeforeLogin = () => {
    setIsFaqModalOpenBeforeLogin(false);
  };

  const handleFaqCancelBeforeLogin = () => {
    setIsFaqModalOpenBeforeLogin(false);
  };

  const RenderFaqModal = () => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const { Panel } = Collapse;
    return (
      <div>
        <div className={classes.faqModalHeading}>Frequently Asked Questions</div>
        <Input
          type="text"
          placeholder="Search Frequently Asked Questions"
          className={classes.FaqSearchInput}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
        <Collapse className={classes.faqCollapse}>
          {
            faqAndAnswers.filter((val) => {
              if (val.ques.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
              }
              return null;
            }).map((i) => {
              return (
                <Panel header={i.ques} key={i.key} className={classes.faqPanel}>
                  <p className={classes.faqAnswers}>{i.ans}</p>
                </Panel>
              );
            })
          }
        </Collapse>
      </div>
    );
  };

  const renderFaqPage = () => {
    return (
      <div>
        <a
          onClick={showFaqModalBeforeLogin}
          style={{ fontFamily: 'Noto Sans' }}
        >
          Learn More About Drop Off Process &gt;&gt;
        </a>
        <Modal
          bodyStyle={{ height: 1800 }}
          width={1950}
          style={{ top: 10 }}
          onOk={handleFaqOkBeforeLogin}
          open={isFaqModalOpenBeforeLogin}
          onCancel={handleFaqCancelBeforeLogin}
          closeIcon={(
            <CloseOutlined
              style={{
                color: 'black',
                fontSize: '40px',
                margin: '10px 10px 10px 0px',
              }}
            />
          )}
          footer={null}
        >
          {RenderFaqModal()}
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.headingContainer}>
        {renderPageHeading()}
      </div>
      <div className={classes.signInForm}>
        <div
          className={[classes.formHeader, classes.workspaceHeader].join(' ')}
        >
          Login using existing EFR/EBR credentials
        </div>
        {renderSignInForm()}
      </div>
      <div className={classes.faqPageLink}>
        {renderFaqPage()}
      </div>
    </div>
  );
};

const hocConfig: HocOptions = {
  connectJss: {
    useJss: true,
    styleSheet: loginStyles,
  },
  connectRedux: {
    useRedux: true,
  },
};

const Login = GenericHoc(hocConfig)(SignIn);
export default Login;
