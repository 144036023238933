export const loginStyles = {
  continueButton: {
    height: '48px',
    width: '124px',
    fontFamily: 'Noto Sans',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53',
    },
  },
  emailLabel: {
    '& .ant-form-item-explain': {
      height: '4px',
      minHeight: '4px',
      marginLeft: '4px',
      textAlign: 'left',
      fontFamily: 'Noto Sans',
      fontSize: '10px',
      fontWeight: 600,
    },
    color: '#333333',
    fontFamily: 'Noto Sans',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  emailInput: {
    fontSize: '14px',
    textAlign: 'left',
    height: '42px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    fontFamily: 'Noto Sans',
  },
  leftSide: {
    width: '48.75%',
  },
  rightSide: {
    width: '51.25%',
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    textAlign: 'left',
    color: '#333333',
    fontFamily: 'Noto Sans',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '16px',
  },
  navInfo: {
    '@media screen and (max-width: 600px)': {
      display: 'none',
    },
    lineHeight: '2',
  },
  blueBar: {
    background: '#165594',
    '@media (min-width: 768px)': { display: 'none' },
    width: '100%',
    height: '80px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noLabelFields: {
    marginLeft: 0,
    '@media (min-width: 576px)': { marginLeft: '25%' },
  },
  responsivePadding: {
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  headingContainer: {
    margin: '70px 70px 0px 70px',
  },
  loginPageHeading: {
    height: '500px',
    fontSize: '280%',
    fontWeight: 'bold',
    paddingTop: '200px',
    fontFamily: 'Noto Sans',
  },
  signUpForm: {
    width: '100%',
    padding: '22.66% 14.64% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signInForm: {
    width: '100%',
    padding: '10% 14.64% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  faqPageLink: {
    margin: ' 10px 70px 70px 95px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0E2C53',
  },
  signUpDetailsForm: {
    width: '100%',
    padding: '5.6% 9.69% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  signInWorkspaceForm: {
    width: '100%',
    padding: '28.65% 7.91% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  resetPasswordForm: {
    width: '100%',
    padding: '22.94% 6.44% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  forgotPasswordForm: {
    width: '100%',
    padding: '29.43% 7.32% 0 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  emailVerification: {
    width: '100%',
    padding: '31.9% 10.98% 0px 13.32%',
    '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
  },
  forgotPassDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '@media (min-width: 576px)': { width: '83.33%' },
  },
  workspaceHeader: {
    paddingBottom: '16px',
    width: '83.3%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  forgotPassword: {
    color: '#999999',
    fontFamily: 'Noto Sans',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  carouselText: {
    padding: '20px 21.37%',
    color: '#FFFFFF',
    fontFamily: 'Noto Sans',
    fontSize: 42,
    fontWeight: 600,
    textAlign: 'center',
  },
  smallText: {
    color: '#FFFFFF',
    fontFamily: 'Noto Sans',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
  },
  carouselContent: {
    padding: '21.48% 0',
  },
  dots: {
    display: 'none',
  },
  logoStyle: {
    position: 'absolute',
    left: '24px',
    top: '24px',
    width: '30.08%',
  },
  carouselStyle: {
    '& .ant-carousel': {
      height: '100%',
    },
  },
  carouselImage: {
    height: '100%',
    width: '100%',
  },
  parentDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  faqModalHeading: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 40,
    fontFamily: 'Noto Sans',
  },
  FaqSearchInput: {
    marginTop: 25,
    marginBottom: 25,
    borderRadius: 15,
    marginRight: '5%',
    marginLeft: '5%',
    borderColor: 'black',
    height: 45,
    width: '90%',
    alignItems: 'center',
  },
  faqCollapse: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
  faqPanel: {
    marginTop: 10,
    marginRight: '7',
    marginLeft: '7%',
    width: '86%',
    borderColor: 'black',
    fontWeight: 'bold',
    fontFamily: 'Noto Sans',
    fontSize: 20,
  },
  faqAnswers: {
    fontWeight: 'normal',
  },
};
