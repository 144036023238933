import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

export const tableColumns = [
  {
    pretty_name: 'AWB',
    column_id: 'reference_number',
    width: 150,
  },
  {
    pretty_name: 'Created Date',
    column_id: 'created_date',
    width: 150,
  },
  {
    pretty_name: 'Origin Pincode',
    column_id: 'origin_pincode',
    width: 150,
  },
  {
    pretty_name: 'Hub Code',
    column_id: 'hub_code',
    width: 150,
  },
  {
    pretty_name: 'Branch Name',
    column_id: 'branch_name',
    width: 150,
  },
  {
    pretty_name: 'Service Type',
    column_id: 'service_type',
    width: 150,
  },
  {
    pretty_name: 'CN Type',
    column_id: 'consignment_type',
    width: 150,
  },
  {
    pretty_name: 'Content Type',
    column_id: 'content_type',
    width: 150,
  },
  {
    pretty_name: 'Load Type',
    column_id: 'load_type',
    width: 150,
  },
  {
    pretty_name: 'Weight (kg)',
    column_id: 'weight',
    width: 150,
    sorter: true,
  },
  {
    pretty_name: 'Dimensions (l x b x h)',
    column_id: 'dimensions',
    width: 160,
    sorter: true,
  },
  {
    pretty_name: 'Packing Material',
    column_id: 'packingMaterial',
    width: 150,
    sorter: true,
  },
  {
    pretty_name: 'Drop-Off Scheduled At',
    column_id: 'dropoff_schedule_time',
    width: 200,
    sorter: true,
  },
  {
    pretty_name: 'Amount (INR)',
    column_id: 'amount',
    width: 150,
    sorter: true,
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
    width: 150,
  },
  {
    pretty_name: 'Completion Date',
    column_id: 'event_time',
    width: 150,
  },
  {
    pretty_name: 'Action',
    column_id: 'action',
    width: 150,
  },
];

export const weightMismatchColumns: ColumnsType<any> = [
  {
    key: '1',
    title: 'S.No',
    dataIndex: 'id',
    align: 'center',
  },
  {
    key: '2',
    title: 'CONSIGNMENT NUMBER',
    dataIndex: 'referenceNumber',
    align: 'center',

  },
  {
    key: '3',
    title: 'WEIGHT(KG)',
    dataIndex: 'weight',
    align: 'center',
  },
  {
    key: '4',
    title: 'LENGTH(CM)',
    dataIndex: 'length',
    align: 'center',

  },
  {
    key: '5',
    title: 'BREADTH(CM)',
    dataIndex: 'breadth',
    align: 'center',

  },
  {
    key: '6',
    title: 'HEIGHT(CM)',
    dataIndex: 'height',
    align: 'center',
  },
];

export const faqAndAnswers = [
  {
    key: 0,
    ques: 'What is the Drop Off feature in MyDTDC Application?',
    ans: 'Using this feature, customers can book a consignment in their MyDTDC mobile application and choose a DTDC outlet to drop off their consignment on any working day as per their convenience.',
  },
  {
    key: 1,
    ques: 'How to book a Consignment for Drop-Off?',
    ans: 'After entering the Origin and Destination addresses, customers can select Drop-Off (if the Drop-Off facility is available in the Origin city). They can select one of the DTDC Outlets in the next screen and Drop-Off their consignments in the same Outlet selected during booking or any other outlet in the city.',
  },
  {
    key: 2,
    ques: 'Can I pay in cash while I Drop-Off our consignments at the DTDC Outlet?',
    ans: 'No. You have to complete the payment in the MyDTDC application. Once the payment is complete you can Drop-Off your consignment at the DTDC Outlet.',
  },
  {
    key: 3,
    ques: 'Do I need to pay any additional charges for Drop off apart from the DTDC courier charges?',
    ans: 'No. You will have to pay the DTDC services charges only.',
  },
  {
    key: 4,
    ques: 'What are the details I have to share with the DTDC Executive at the DTDC Outlet while dropping off our consignment?',
    ans: 'You have to share the AWB number and the Drop-Off code with the DTDC Executive. You can find those details at the Order History section of MyDTDC application. The DTDC Executive will verify the consignment details and complete the Drop-Off.',
  },
  {
    key: 5,
    ques: 'What if I have lost my Drop Off code/ SMS containing Drop off code?',
    ans: 'In case you have lost your Drop off code/ SMS, you can go to the Consignment detail in the Bookings Summary section inside MyDTDC application to find your Drop Off code.',
  },
  {
    key: 6,
    ques: 'Will the DTDC Outlet provide a packaging solution for our consignment?',
    ans: 'Currently, the DTDC Outlets will not provide any packaging services. The customer has to complete the packaging and Drop-Off the consignment. However, if requested by customer, the outlets can sell a Packaging material in Cash.',
  },
  {
    key: 7,
    ques: 'Can I Drop-Off my consignment at any DTDC Outlet?',
    ans: 'The customer has to Drop-Off the consignment at the DTDC Outlet selected during booking or in any other Outlet within the city limits. DTDC Outlets in any other city will not accept the Drop-Off',
  },
  {
    key: 8,
    ques: 'At what time can I visit a DTDC outlet for Drop Off?',
    ans: 'You can visit the DTDC store selected for Drop Off on any working day between 10am-6pm.',
  },
  {
    key: 9,
    ques: 'If the Drop-Off is rejected once, can I still reattempt the Drop-Off?',
    ans: 'Yes. You can still reattempt to Drop-Off your consignment in the DTDC Outlet selected during booking or in any other DTDC Outlet in the origin city.',
  },
  {
    key: 10,
    ques: 'What if I failed to Drop Off my consignment on the selected date?',
    ans: 'In case you could not arrive at the selected date for Drop off, you can still go ahead and Drop Off your consignment on any other working day.',
  },
  {
    key: 11,
    ques: 'Is the Drop-Off feature available for all cities in the country?',
    ans: 'No. Currently we are not allowing Drop-Off for all the cities in the country. If we are providing a Drop-Off facility in your city, you can see the Drop-Off option after entering the Origin and Destination addresses.If Drop off service is not available in your area only the Pickup option will be visible.',
  },
  {
    key: 12,
    ques: 'What are the additional documents required while Dropping off a consignment?',
    ans: 'If you are booking a Domestic consignment, you need to carry an ID proof with you  while Dropping-Off your consignment. If it is an International consignment, along with an ID proof, you need to carry your KYC, an Authorisation letter and Customer Invoice.',
  },
  {
    key: 13,
    ques: 'How can I track a consignment which I dropped off at a DTDC outlet?',
    ans: 'You can track the status of your consignment by\n1.Visiting DTDC website\n2.In MyDTDC App >> Tracking section',
  },
  {
    key: 14,
    ques: 'How can you cancel a Drop-Off consignment?',
    ans: 'You can cancel your consignment by visiting the Booking Summary section in the MyDTDC application. Once canceled, you will receive your Refund within 10-15 working days.',
  },
  {
    key: 15,
    ques: 'Till what point is Drop-Off cancellation allowed?',
    ans: 'Consignment can be cancelled till the consignment gets booked at the DTDC branch. Once the consignment gets booked at the hub, cancellation is not allowed.',
  },
  {
    key: 16,
    ques: 'How can customers get back their cancelled consignments?',
    ans: 'Customers can get back their cancelled consignments from the DTDC Outlet or Franchisee at which the Drop-Off was completed',
  },
];

export function getColumns(status:any) {
  let finalColumns = tableColumns;
  if (status === 'COMPLETED') {
    finalColumns = tableColumns;
  } else if (status === 'REJECTED') {
    finalColumns.forEach((el) => {
      if (el.column_id === 'event_time') {
        /* eslint-disable no-param-reassign */
        el.pretty_name = 'Rejection Date';
        /* eslint-enable */
      }
    });
  } else {
    finalColumns = tableColumns.filter((el) => el.column_id !== 'action' && el.column_id !== 'event_time');
  }

  return finalColumns;
}

export const collectionCompleteDiableText = 'Drop-Off cannot be completed at the moment because the payment is still awaited. Please ask the customer to complete the additional payment.';

export interface DropoffTableData {
  reference_number: string,
  created_date: string,
  origin_pincode: string,
  hub_code: string,
  branch_name: string,
  hub_type: string,
  consignment_type: string,
  load_type: string,
  content_type: string,
  weight: number,
  dropoff_schedule_time: string,
  service_type: string,
  amount: number,
  status: string,
  action: any,
}

export interface DropoffTableParams {
  currentPage: number,
  organisationId: number,
  fromDate: string,
  toDate: string,
  hubCode: string,
  resultPerPage: number,
  sortBy: string,
  ascendingOrder: boolean,
  searchFilter: string
}
export const getInitialTableParams = () => ({
  currentPage: 1,
  fromDate: moment().format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  hubCode: window.localStorage.getItem('hubCode') || '',
  resultPerPage: 10,
  organisationId: window.localStorage.organisationId,
  sortBy: '',
  ascendingOrder: true,
  searchFilter: '',
});
