import * as React from 'react';
import withStyles from 'react-jss';
import {
  Row,
  Col,
} from 'antd';
import Carousels from '@routing/Carousels';
import { Switch, Route } from 'react-router-dom';
import {
  getForgotPasswordRoute,
  getLoginRoute,
} from '@routing/routing-helper';
import { StylesProps } from '@theme/jss-types';
import Loader from '@common/Loader';
import Login from '@login/sign-in';
import DefaultUrl from '@routing/default-page';
import authHelper from '@auth/auth-helper';
import { LOGO_URL } from '@src/globals';

const styles = {
  parentDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  logoStyle: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: 'auto',
    maxHeight: 'min(10vh, 100px)',
  },
};

interface IProps extends StylesProps<typeof styles> {
}
const UnAuthPages = (props: IProps) => {
  const { classes } = props;
  const carouselRef = React.createRef<HTMLDivElement>();

  const [formHeight, setFormHeight] = React.useState(Math.max(window.innerHeight, 816));
  const [isLogoWhite, setIsLogoWhite] = React.useState(window.innerWidth >= 768);
  const [logourl, setLogourl] = React.useState(LOGO_URL);

  const loadOrgUsernameAndLogo = () => {
    const {
      logoUrl,
    } = authHelper.getUsernameAndLogo();
    setLogourl(logoUrl || LOGO_URL);
  };

  React.useEffect(() => {
    loadOrgUsernameAndLogo();
  }, []);

  const changeFormHeight = () => {
    setFormHeight(Math.max(window.innerHeight, 800));
  };
  React.useEffect(() => {
    window.addEventListener('resize', changeFormHeight);

    return () => {
      window.removeEventListener('resize', changeFormHeight);
    };
  }, []);

  const changeLogo = () => {
    const width = window.innerWidth;
    if (width < 768 && isLogoWhite) {
      setIsLogoWhite(true);
    }
    if (width >= 768 && !isLogoWhite) {
      setIsLogoWhite(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', changeLogo);
    return () => {
      window.removeEventListener('resize', changeLogo);
    };
  }, [window.innerWidth]);

  const renderLogo = () => {
    return (
      <img
        className={classes.logoStyle}
        src={logourl as string}
        alt="Shipsy"
      />
    );
  };

  const renderPages = () => {
    return (
      <React.Suspense fallback={<Loader zIndex={5} />}>
        <Switch>
          <Route
            exact
            path={getLoginRoute()}
            render={() => <Login />}
          />
          <Route
            exact
            render={() => <Login />}
            path={getForgotPasswordRoute()}
          />
          <Route
            path="/"
            render={() => <DefaultUrl />}
          />
        </Switch>
      </React.Suspense>
    );
  };

  return (
    <Row className={classes.parentDiv}>
      <Col
        style={{ top: 0 }}
        xs={0}
        sm={0}
        md={12}
        lg={12}
        ref={carouselRef}
      >
        <Carousels />
      </Col>
      <Col
        style={{
          height: formHeight,
          background: 'white',
        }}
        xs={24}
        sm={24}
        md={12}
        lg={12}
      >
        {renderLogo()}
        {renderPages()}
      </Col>
    </Row>
  );
};

const UnauthenticatedPages = withStyles(styles)(UnAuthPages);
export default UnauthenticatedPages;
