import moment from 'moment';
import { GenericAction } from '@reducers/redux.types';
import {
  FETCH_TABULAR_DROPOFF_DETAILS_INITIATED,
  FETCH_TABULAR_DROPOFF_DETAILS_FAILED,
  FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED,
  FETCH_TABULAR_DROPOFF_SET_DATE,
  FETCH_TABULAR_DROPOFF_SET_FROM_DATE,
  FETCH_TABULAR_DROPOFF_PAGE_CHANGE,
  TABULAR_DROPOFF_TABLE_CHANGE,
  TABULAR_DROPOFF_SEARCH_FILTER,
} from '@reducers/redux-constants';

const genericState = {
  data: [],
  isLoading: false,
  total_result: null,
};

const defaultPagination = {
  currentPage: 1,
  ascendingOrder: true,
  resultPerPage: '50',
};

const appliedFilters = {
  fromDate: moment().format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  searchFilter: '',
  sortBy: '',
};

const defaultState: any = {
  tableData: genericState,
  pagination: defaultPagination,
  dropoffFilters: appliedFilters,
  hubCode: window.localStorage.getItem('hubCode') || '',
  organisationId: window.localStorage.organisationId,
};


function initiateTabularDropoffDetails(state: any) {
  return {
    ...state,
    tableData: {
      ...state.tableData,
      isLoading: true,
    },
  };
}

function failedTabularDropoffDetails(state: any, action: GenericAction) {
  return {
    ...state,
    tableData: {
      data: action.data,
      isLoading: false,
      total_result: null,
    },
  };
}

function fetchTabularDropoffDetails(state: any, action: GenericAction) {
  return {
    ...state,
    tableData: {
      data: action.data,
      isLoading: false,
      total_result: action.total_result,
      upcoming_cash_booking: action.upcoming_cash_booking,
    },
  };
}

function setDateTabularDropoff(state: any, action: GenericAction) {
  return {
    ...state,
    dropoffFilters: {
      ...state.dropoffFilters,
      fromDate: action.data.start,
      toDate: action.data.end,
    },
  };
}

function setFromDateTabularDropoff(state: any, action: GenericAction) {
  return {
    ...state,
    dropoffFilters: {
      ...state.dropoffFilters,
      fromDate: action.data,
    },
  };
}


function setPageTabularDropoff(state: any, action: GenericAction) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: action.data,
    },
  };
}

function handleDropoffTableChange(state: any, action: GenericAction) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      ascendingOrder: action.data.order === 'ascend',
    },
    dropoffFilters: {
      ...state.dropoffFilters,
      sortBy: action.data.order ? action.data.field : '',
    },
  };
}

function setSearchFilterTabularDropoff(state: any, action: GenericAction) {
  return {
    ...state,
    dropoffFilters: {
      ...state.dropoffFilters,
      searchFilter: action.data,
    },
  };
}

const dropoffReducer = (state = defaultState, action: GenericAction) => {
  switch (action.type) {
    default: return state;
    case FETCH_TABULAR_DROPOFF_DETAILS_INITIATED: return initiateTabularDropoffDetails(state);
    case FETCH_TABULAR_DROPOFF_DETAILS_FAILED: return failedTabularDropoffDetails(state, action);
    case FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED: return fetchTabularDropoffDetails(state, action);
    case FETCH_TABULAR_DROPOFF_SET_DATE: return setDateTabularDropoff(state, action);
    case FETCH_TABULAR_DROPOFF_SET_FROM_DATE: return setFromDateTabularDropoff(state, action);
    case FETCH_TABULAR_DROPOFF_PAGE_CHANGE: return setPageTabularDropoff(state, action);
    case TABULAR_DROPOFF_TABLE_CHANGE: return handleDropoffTableChange(state, action);
    case TABULAR_DROPOFF_SEARCH_FILTER: return setSearchFilterTabularDropoff(state, action);
  }
};

export default dropoffReducer;
