export const SET_UI_THEME = 'SET_UI_THEME';
export const SET_MASTER_DATA = 'SET_MASTER_DATA';
export const SET_FETCHING_DATA = 'SET_FETCHING_DATA';
export const FETCH_TABULAR_DROPOFF_DETAILS_INITIATED = 'FETCH_TABULAR_DROPOFF_DETAILS_INITIATED';
export const FETCH_TABULAR_DROPOFF_DETAILS_FAILED = 'FETCH_TABULAR_DROPOFF_DETAILS_FAILED';
export const FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED = 'FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED';
export const FETCH_TABULAR_DROPOFF_SET_DATE = 'FETCH_TABULAR_DROPOFF_SET_DATE';
export const FETCH_TABULAR_DROPOFF_SET_FROM_DATE = 'FETCH_TABULAR_DROPOFF_SET_FROM_DATE';
export const FETCH_TABULAR_DROPOFF_PAGE_CHANGE = 'FETCH_TABULAR_DROPOFF_PAGE_CHANGE';
export const TABULAR_DROPOFF_TABLE_CHANGE = 'TABULAR_DROPOFF_TABLE_CHANGE';
export const TABULAR_DROPOFF_SEARCH_FILTER = 'TABULAR_DROPOFF_SEARCH_FILTER';
