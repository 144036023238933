import { ONLINE_BOOKING_API_URL } from '@src/globals';
import {
  DROPOFF_VERIFICATION,
  DROPOFF_CONSIGNMENT_DETAILS,
  GET_ECNOTE_URL,
  GET_CUSTOM_INVOICE,
  GET_AUTH_LETTER,
  GET_SHIPPING_LABEL,
  RESEND_DROPOFF_SMS_URL,
  WEIGHT_MISMATCH_VALUES,
  GET_WEIGHT_MISMATCH,
  WEIGHT_MISMATCH_PAYMENT,
  PACKAGING_OPTIONS,
  PACKAGING_SEND_PAYMENT_LINK,
  UPDATE_PACKAGING_MATERIAL_DATA,
} from '@network/api.constants';
import { GET, POST } from '@network/api-hander.service';

export const verifyDropoff = async (params: {
  referenceNumber: string,
  validationCode: string,
  hubCode: string
  isCompletionRequest : boolean,
}) => {
  return GET(`${ONLINE_BOOKING_API_URL}${DROPOFF_VERIFICATION}`, params);
};

export const dropOffConsignmentDetails = async (obj: any) => {
  return POST(`${ONLINE_BOOKING_API_URL}${DROPOFF_CONSIGNMENT_DETAILS}`, obj);
};

export const getEcnoteUrl = async (params:{
  referenceNumber?: string,
  referenceNumberList?: Array<string>,
  showPriceInECNote: boolean,
  source: string | null,
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${GET_ECNOTE_URL}`, params, true);
};

export const getCustomInvoice = async (params:{
  awbno: string | null,
}) => {
  return GET(`${ONLINE_BOOKING_API_URL}${GET_CUSTOM_INVOICE}`, params, 'blob');
};

export const getAuthLetter = async (params:{
  awbno: string | null,
}) => {
  return GET(`${ONLINE_BOOKING_API_URL}${GET_AUTH_LETTER}`, params, 'blob');
};

export const getShippingLabelUrl = async (params:{
  referenceNumbers: Array<string>,
  isSmall: boolean,
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${GET_SHIPPING_LABEL}`, params, true);
};

export const resendSMS = async (params:{
  referenceNumber: string,
  username: string | null,
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${RESEND_DROPOFF_SMS_URL}`, params, false);
};
export const weightMismatchValues = async (params: {
  consignments: string[],
}) => {
  return GET(`${ONLINE_BOOKING_API_URL}${WEIGHT_MISMATCH_VALUES}`, params);
};
export const getWeightMismatchSubmit = async (params: {
  consignments: any,
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${GET_WEIGHT_MISMATCH}`, params);
};

export const weightMismatchPaymentLink = async (params: {
  consignments: string[],
  email: string,
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${WEIGHT_MISMATCH_PAYMENT}`, params);
};

export const getPackageOptions = async (params: Record<any, any>) => {
  return GET(`${ONLINE_BOOKING_API_URL}${PACKAGING_OPTIONS}`, params);
};

export const sendPaymentLinks = async (params: Record<any, any>) => {
  return POST(`${ONLINE_BOOKING_API_URL}${PACKAGING_SEND_PAYMENT_LINK}`, params);
};

export const updatePackingMaterialsData = async (params: Record<any, any>) => {
  return POST(`${ONLINE_BOOKING_API_URL}${UPDATE_PACKAGING_MATERIAL_DATA}`, params);
};

