import authHelper from '@src/auth/auth-helper';
import axios from 'axios';

const apiProvider = axios.create({});

// Add 401 interceptor
apiProvider.interceptors.response.use((response) => {
  return response;
}, async (error: any) => {
  if (error?.response?.status === 401) {
    authHelper.logoutUser();
  }

  return Promise.reject(error);
});

export default apiProvider;
