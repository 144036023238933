import React from 'react';
import withStyles from 'react-jss';
import { loginStyles } from '@components/sign-in/sign-in.styles';
import { StylesProps } from '@theme/jss-types';
import { background } from '@assets/index';

const CarouselPage = (props: StylesProps<typeof loginStyles>): React.ReactElement => {
  const {
    classes,
  } = props;

  const renderText = () => {
    return (
      <div className={classes.carouselText}>
        One of India&apos;s leading Integrated express logistics player
      </div>
    );
  };

  const renderSmallText = () => {
    return (
      <div className={classes.smallText}>
        One Stop Solution For All Your Shipping Needs
      </div>
    );
  };

  const renderCarousel = () => {
    return (
      <div className={classes.carouselContent}>
        {renderText()}
        {renderSmallText()}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        backgroundImage: `url(${background})`,
      }}
    >
      {renderCarousel()}
    </div>
  );
};

const Carousels = withStyles(loginStyles)(CarouselPage);
export default Carousels;
