import { Dispatch } from 'redux';
import { GenericAction } from '@reducers/redux.types';
import {
  FETCH_TABULAR_DROPOFF_DETAILS_INITIATED,
  FETCH_TABULAR_DROPOFF_DETAILS_FAILED,
  FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED,
  FETCH_TABULAR_DROPOFF_SET_DATE,
  FETCH_TABULAR_DROPOFF_SET_FROM_DATE,
  FETCH_TABULAR_DROPOFF_PAGE_CHANGE,
  TABULAR_DROPOFF_TABLE_CHANGE,
  TABULAR_DROPOFF_SEARCH_FILTER,
} from '@reducers/redux-constants';
import { dropOffConsignmentDetails } from '@src/network/dropoff.api';
import { message } from 'antd';

export const fetchAllDropoffDetailsInitiated = () => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_DETAILS_INITIATED,
  };
  dispatch(action);
};

export const fetchAllDropofDetailsFailed = (data: any) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_DETAILS_FAILED,
    data,
  };
  dispatch(action);
};

export const fetchDropoffDetails = (data: any, total_result: number,
  upcoming_cash_booking: number) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_DETAILS_SUCCEEDED,
    data,
    total_result,
    upcoming_cash_booking,
  };
  dispatch(action);
};

export const setdropoffDate = (arr:any) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_SET_DATE,
    data: arr,
  };
  dispatch(action);
};

export const handleDropoffPageChange = (page:number) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_PAGE_CHANGE,
    data: page,
  };
  dispatch(action);
};

export const setdropoffFromDate = (arr:any) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: FETCH_TABULAR_DROPOFF_SET_FROM_DATE,
    data: arr,
  };
  dispatch(action);
};

export const handleDropoffSearch = (query:string) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: TABULAR_DROPOFF_SEARCH_FILTER,
    data: query,
  };
  dispatch(action);
};

export const handleDropoffDisplayTableChange = (sorter:any) => (dispatch: Dispatch) => {
  const action: GenericAction = {
    type: TABULAR_DROPOFF_TABLE_CHANGE,
    data: sorter,
  };
  dispatch(action);
};

export const loadDropoffData = (dropoffStatus:string) => async (
  dispatch: Dispatch<any>,
  store: () => any,
) => {
  dispatch(fetchAllDropoffDetailsInitiated());
  const { dropoffReducer } = store();
  const {
    dropoffFilters,
    pagination,
    organisationId,
  } = dropoffReducer;
  const obj = {
    ...dropoffFilters,
    ...pagination,
    hubCode: window.localStorage.getItem('hubCode') || '',
    organisationId,
    status: dropoffStatus,
  };
  const response:any = await dropOffConsignmentDetails(obj);
  if (response.isSuccess) {
    dispatch(fetchDropoffDetails(response.data.page_data || [],
      response.data.total_result, response.data.upcoming_cash_booking));
  } else {
    message.error(response.errorMessage);
    dispatch(fetchDropoffDetails([], 0, 0));
  }
};

