import { ResponseType } from 'axios';
import { KeyValue } from '@library/Types';
import { useOnlyForLocalTestingOrgId } from '@src/globals';

const authenticateUser = (req: any) => {
  const { cookies } = req;
  return cookies.organisationID && cookies.userId && cookies.authToken;
};

const isAuthenticated = () => {
  const storage = window.localStorage;
  const token = storage.getItem('authToken');
  const username = storage.getItem('username');
  return (
    token && username
  );
};

const getOrganisationId = () => {
  const { hostname } = window.location;
  let organisationId = useOnlyForLocalTestingOrgId;
  if (hostname.indexOf('.') > 0) {
    organisationId = hostname.substring(0, hostname.indexOf('.'));
  }
  return organisationId;
};

const getAuthenticationHeaders = () => {
  const storage = window.localStorage;
  const organisationId = getOrganisationId();
  const token = storage.getItem('authToken');
  const username = storage.getItem('username');
  return {
    Authorization: `${token} ${username}`,
    'Content-Type': 'application/json',
    'organisation-id': organisationId,
  };
};

const getErrorMessage = (err: any) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

const getErrorMessageForBlob = async (err: any) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

const getErrorForBlob = async (err: any) => {
  let error: any = {};
  if (!err.response) {
    error.message = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    if (responseData && responseData.error) {
      error = responseData.error;
    } else {
      error.message = `Something went wrong\n${err.message}`;
    }
  }
  return error;
};

const generateQueryString = (paramsArray: KeyValue[]) => {
  const queryString = paramsArray.map(({ key, val }) => {
    if (val || val === 0) {
      return `${key}=${encodeURIComponent(val)}`;
    }
    return null;
  }).filter((elem: any) => elem !== null).join('&');
  return queryString;
};

const generateQueryStringFromObject = (obj: any) => {
  if (!obj) {
    return '';
  }
  const keys = Object.keys(obj);
  const x = keys.map((key) => {
    return { key, val: obj[key] };
  });
  return generateQueryString(x);
};

const generateObjectFromList = (key: string, list: any) => {
  const generatedList: any = [];
  if (list) {
    list.forEach((element: any) => {
      generatedList.push({ key, val: element });
    });
  }
  return generatedList;
};

const getFileExtension = (file: any) => {
  return file.name.split('.').pop();
};

const dataHeaders = {
  JPG: 'image/*',
  JPEG: 'image/*',
  PNG: 'image/*',
  PDF: 'application/*',
  DOCX: 'application/*',
  XLSX: 'application/*',
  CSV: 'text/csv',
  XLS: 'application/*',
  DOC: 'application/*',
};

const logoutUser = () => {
  // Remove auth storage
  const storage = window.localStorage;
  storage.removeItem('username');
  storage.removeItem('userId');
  storage.removeItem('authToken');
  storage.removeItem('organisationId');
  storage.removeItem('hubCode');
  storage.removeItem('allowCashPaymentForDropOff');
  window.location.href = '/login';
};

const getErrorMessageCommon = (err: any, responseType?: ResponseType) => {
  if (responseType === 'blob') {
    return getErrorMessageForBlob(err);
  }
  return getErrorMessage(err);
};

const getUsernameAndLogo = () => {
  const storage = window.localStorage;
  const username = storage.getItem('username');
  const logoUrl = storage.getItem('logoUrl');
  return {
    username,
    logoUrl,
  };
};

export default {
  getErrorMessageCommon,
  authenticateUser,
  getAuthenticationHeaders,
  logoutUser,
  getErrorMessage,
  dataHeaders,
  getFileExtension,
  generateObjectFromList,
  generateQueryStringFromObject,
  generateQueryString,
  getErrorForBlob,
  getErrorMessageForBlob,
  isAuthenticated,
  getUsernameAndLogo,
};
