/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import '@src/index.css';
import App from '@src/App';
import { JssProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '@redux-store/store';
import JssThemeProvider from '@theme/theme-provider';

declare const module: any;

const launchApp = (Component: any) => {
  ReactDOM.render(
    <Provider store={store}>
      <JssProvider>
        <JssThemeProvider>
          <BrowserRouter>
            <Component />
          </BrowserRouter>
        </JssThemeProvider>
      </JssProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

const launchMain = () => launchApp(App);
launchMain();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js');
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (module.hot && process.env.NODE_ENV !== 'production') {
  // module.hot.accept();
  module.hot.accept('./App', () => {
    /* eslint-disable-next-line global-require */
    const NextApp = require('./App').default;
    launchApp(NextApp);
  });
}
