import * as React from 'react';
import { HocOptions } from '@common/generic-hoc.types';
import AuthHelper from '@auth/auth-helper';
import UnauthenticatedPages from '@routing/unauthenticated';
import Authenticated from '@routing/authenticated';
import GenericHoc from '@common/generic-hoc';

const Routing = () => {
  const authenticated = AuthHelper.isAuthenticated();

  if (!authenticated) {
    return <UnauthenticatedPages />;
  }
  return <Authenticated />;
};

const hocConfig: HocOptions = {
  connectRouter: true,
};

export default GenericHoc(hocConfig)(Routing);
