import React from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export interface Tab {
  name: string;
  key?: string;
  Component: React.ReactNode;
}

interface CustomTabsProps {
  tabs: Tab[];
  defaultActiveKey?: string;
  [key: string]: any;
}

const CustomTabs: React.FunctionComponent<CustomTabsProps> = ({
  tabs = [],
  defaultActiveKey = '1',
  ...props
}) => {
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      type="card"
      size="large"
      style={{ fontSize: '14px' }}
      {...props}
    >
      {tabs.map(({ name, Component, key }, index) => {
        return (
          <TabPane tab={name} key={key || index + 1}>
            {Component}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

CustomTabs.defaultProps = {
  defaultActiveKey: '1',
};
export default CustomTabs;
