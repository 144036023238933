const config = require('./config').default;

const {
  useOnlyForLocalTestingOrgId,
  LOGO_URL,
  ONLINE_BOOKING_API_URL,
} = config;

export const NAVBAR_HEIGHT = 63;
export const MOBILE_BREAK_POINT = 590;
export const MIN_WIDTH = 1200;

export {
  useOnlyForLocalTestingOrgId,
  LOGO_URL,
  ONLINE_BOOKING_API_URL,
};
