export const LOGIN = '/PUDO-dashboard/login';
export const DROPOFF_VERIFICATION = '/dropoff/parcelVerification';
export const DROPOFF_CONSIGNMENT_DETAILS = '/dropoff/consignmentDetails';
export const GET_ECNOTE_URL = '/downloadCnote/stream';
export const GET_CUSTOM_INVOICE = '/iboooking/download/invoice';
export const GET_AUTH_LETTER = '/iboooking/download/authorizationLetter';
export const GET_SHIPPING_LABEL = '/downloadShippingLabel/stream';
export const RESEND_DROPOFF_SMS_URL = '/dropoff/resendValidationCode';
export const WEIGHT_MISMATCH_VALUES = '/dropoff/getdataformismatch';
export const GET_WEIGHT_MISMATCH = '/dropoff/weightmismatch';
export const WEIGHT_MISMATCH_PAYMENT = '/dropoff/initiatePaymentWeightmismatch';


// Packaging Options
export const PACKAGING_OPTIONS = '/dropoff/loadPackingMaterialsList';
export const UPDATE_PACKAGING_MATERIAL_DATA = '/dropoff/updatePackingMaterialsData';
export const PACKAGING_SEND_PAYMENT_LINK = '/dropoff/initiatePaymentPackingMaterials';
