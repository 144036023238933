import * as React from 'react';
import { HocOptions } from '@common/generic-hoc.types';
import { StylesProps } from '@theme/jss-types';
import { RouteChildrenProps } from 'react-router';
import GenericHoc from '@common/generic-hoc';
import PackageOptionsStyles from './packageOptions.styles';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import { getPackageOptions, sendPaymentLinks, updatePackingMaterialsData } from '@src/network/dropoff.api';
import { CloseCircleFilled } from '@ant-design/icons';
import authHelper from '@src/auth/auth-helper';


interface PackageProps
  extends StylesProps<ReturnType<typeof PackageOptionsStyles>>, RouteChildrenProps {
  isPackageModalOpen: boolean;
  referenceNumber: string,
  setIsPackageModalOpen: (isOpen: boolean) => void;
  handleReloadDropOff: () => void;
  verifiedConsignmentDetail: Record<string, any>;
  showPaymentStatus: string;
  isInternational: boolean;
}

const PackagePage = (props : PackageProps) => {
  const {
    classes,
    isPackageModalOpen,
    setIsPackageModalOpen,
    referenceNumber,
    verifiedConsignmentDetail,
    handleReloadDropOff,
    showPaymentStatus,
    isInternational,
  } = props;
  const [packageOptionsData, setPackageOptionsData] = React.useState<any>();
  const [selectedPackageDetails, setSelectedPackageDetails] = React.useState<any[]>([]);
  const [selectedPackageList, setSelectedPackageList] = React.useState<string[]>([]);
  const [showPaymentModal, setShowPaymentModal] = React.useState<boolean>(false);
  const [totalPayment, setTotalPayment] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const { Text } = Typography;
  const {
    username,
  } = authHelper.getUsernameAndLogo();


  const fetchPackageOption = async () => {
    const storage = window.localStorage;
    const hubCode = storage.getItem('hubCode') || '';
    const response = await getPackageOptions({
      hubCode,
      empCode: username,
    });
    if (response.isSuccess) {
      setPackageOptionsData(response.data);
    }
  };

  React.useEffect(() => {
    fetchPackageOption();
  }, []);


  const handleSelectedPackageOptionChange = (list: any[]) => {
    const currentItems = packageOptionsData.filter((item: any) => list.includes(item.itemCode));
    setSelectedPackageDetails(currentItems || []);
    setSelectedPackageList(list || []);
  };


  const validateInput = (max: number, value: any, callback: any) => {
    if (value > max) {
      return callback(`Max quantity is ${max}`);
    }
    if (value === 0) {
      return callback('Quantity cannot be 0');
    }
    return callback();
  };

  const renderRequiredQuantity = (idx: any, max: number) => {
    return (
      <Col span={6} className={classes.inputQuantityCol}>
        <Form.Item
          name={[idx, 'quantity']}
          className={classes.formItem}
          rules={[{
            required: true,
            message: 'Required',
          },
          {
            validator: (rule, value, cb) => validateInput(max, value, cb),
          },
          ]}
        >
          <InputNumber
            className={classes.inputQuantity}
            type="Number"
            placeholder="Quantity"
            min={0}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderUnitPrice = (priceForEach: number, idx: any) => {
    return (
      <Col span={6} className={classes.inputQuantityCol}>
        <Form.Item
          name={[idx, 'priceForEach']}
          rules={[{
            required: true,
            message: 'Required',
          }]}
          className={classes.formItem}
          initialValue={priceForEach}
        >
          <InputNumber
            className={classes.inputQuantity}
            type="Number"
            placeholder="Unit Price"
            disabled
          />
        </Form.Item>
      </Col>
    );
  };

  const renderItemName = (name: string) => {
    return (
      <Col span={8}>
        <div className={classes.itemName}>
          {name}
        </div>
      </Col>
    );
  };

  const renderRemovePackageOption = (id: any) => {
    return (
      <Col span={2} key={id} className={classes.deleteOptionIcon}>
        <Form.Item>
          <div className={classes.deleteIcon}>
            <CloseCircleFilled
              disabled={loading}
              onClick={() => handleSelectedPackageOptionChange(
                selectedPackageList.filter((selectedId) => selectedId !== id),
              )}
            />
          </div>
        </Form.Item>
      </Col>
    );
  };

  const renderQuantitySelectionInputRow = (packageItem: Record<string, any>) => {
    return (
      <Row key={packageItem.itemCode} className={classes.quantityRow}>
        {renderItemName(packageItem.itemDesc)}
        {renderRequiredQuantity(packageItem.itemCode, packageItem.availableQuantity)}
        {renderUnitPrice(packageItem.priceForEach, packageItem.itemCode)}
        {renderRemovePackageOption(packageItem.itemCode)}
        {/* {packageItem.allowMultiQty === 'Y'} */}
      </Row>
    );
  };

  const renderQuantitySelection = () => {
    return (
      <div>
        {selectedPackageDetails?.length
          ? (
            <Row className={classes.tableHeader}>
              <Col span={8} />
              <Col className={classes.tableHeaderRowQuantity} span={6}>Quantity Required</Col>
              <Col className={classes.tableHeaderRowPrice} span={6}>Unit Price</Col>
            </Row>
          ) : null}
        {selectedPackageDetails.map((item) => renderQuantitySelectionInputRow(item))}
      </div>
    );
  };

  const calculateTotalPayment = () => {
    if (totalPayment) return totalPayment;
    const formData = form.getFieldsValue();
    let sum = 0;
    Object.keys(formData || {})?.forEach((key: any) => {
      const item = formData[key];
      sum += (item?.priceForEach * item?.quantity);
    });
    setTotalPayment(sum);
    return sum;
  };

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <Form.Item>
          <Button
            className={classes.acceptButton}
            type="primary"
            htmlType="submit"
            disabled={selectedPackageDetails?.length === 0 || loading}
            onClick={async () => {
              const formValues = await form.validateFields();
              if (formValues.errorFields?.length) {
                return;
              }
              const formData = form.getFieldsValue();
              const packingMaterial = selectedPackageDetails?.map((item) => {
                return {
                  ...item,
                  purchaseQuantity: formData[item?.itemCode]?.quantity,
                  price: formData[item?.itemCode]?.quantity
                    * formData[item?.itemCode]?.priceForEach,
                };
              });
              const apiData = {
                consignments: [
                  {
                    referenceNumber,
                    courierType: verifiedConsignmentDetail?.loadType,
                    noOfPieces: 1,
                    VAS: {
                      isPacking: true,
                      packingDetails: {
                        packingMaterial,
                        price: calculateTotalPayment(),
                      },
                    },
                    isInternational,
                  },
                ],
              };
              setLoading(true);
              const response = await updatePackingMaterialsData(apiData);
              setLoading(false);
              if (response.isSuccess && showPaymentStatus === 'PENDING') {
                handleReloadDropOff();
                setIsPackageModalOpen(false);
              } else if (response.isSuccess) {
                setShowPaymentModal(true);
              } else {
                message.error(response?.errorMessage || 'Something went wrong!');
              }
            }}
          >
            Accept
            <Spin
              spinning={loading}
              size="small"
              style={{ color: 'white' }}
            />
          </Button>
        </Form.Item>
        <Button
          className={classes.cancelButton}
          type="link"
          onClick={() => setIsPackageModalOpen(false)}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const renderFooterPayment = () => {
    return (
      <div className={classes.footer}>
        <Form.Item>
          <Button
            className={classes.acceptButton}
            type="primary"
            htmlType="submit"
            disabled={selectedPackageDetails?.length === 0}
            onClick={async () => {
              const formValues = await form.validateFields();
              if (formValues.errorFields?.length) {
                return;
              }
              const apiData = {
                consignments: [
                  {
                    referenceNumber,
                    phone: formValues.phone,
                    email: formValues.email,
                  },
                ],
              };
              setLoading(true);
              const response = await sendPaymentLinks(apiData);
              setLoading(false);

              if (response.isSuccess) {
                handleReloadDropOff();
                setIsPackageModalOpen(false);
                setShowPaymentModal(false);
              } else {
                message.error(response?.errorMessage || 'Something went wrong!');
              }
            }}
          >
            Send Payment Link
            <Spin
              spinning={loading}
              size="small"
              style={{ color: 'white' }}
            />
          </Button>
        </Form.Item>
        <Text type="danger" className={classes.additionalAmount}>
          &nbsp;
          Additional Payment:
          &nbsp;
          {calculateTotalPayment()}
        </Text>
      </div>
    );
  };

  const closeModal = () => {
    setIsPackageModalOpen(false);
    setSelectedPackageDetails([]);
    setSelectedPackageList([]);
  };

  const renderModal = () => {
    return (
      <Modal
        className={classes.modal}
        open={isPackageModalOpen}
        onCancel={closeModal}
        onOk={() => setIsPackageModalOpen(false)}
        title="Add Packaging Material"
        footer={null}
        destroyOnClose
        maskClosable={false}
      >
        <div className={classes.selectLabel}>
          Packaging Material
        </div>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            options={packageOptionsData?.map((data: any) => {
              return {
                value: data.itemCode,
                label: data.itemDesc,
              };
            })}
            onChange={(list) => handleSelectedPackageOptionChange(list)}
            value={selectedPackageList}
            maxTagCount="responsive"
            autoClearSearchValue
          />
        </Space>
        {renderQuantitySelection()}
        {renderFooter()}
      </Modal>
    );
  };

  const renderPaymentModal = () => {
    return (
      <Modal
        className={classes.modal}
        open={isPackageModalOpen}
        onCancel={closeModal}
        title="Add Packaging Material"
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <div className={classes.selectLabel}>
          Phone
        </div>
        <Form.Item
          name={['phone']}
          rules={[{
            required: true,
            message: 'Required',
          }]}
          className={classes.formItem}
          initialValue={verifiedConsignmentDetail?.originDetails?.phone || ''}
        >
          <Input
            className={classes.inputQuantity}
            placeholder="Phone Number"
          />
        </Form.Item>
        <div className={classes.selectLabel}>
          Email
        </div>
        <Form.Item
          name={['email']}
          rules={[{
            required: true,
            message: 'Required',
          },
          {
            type: 'email',
          },
          ]}
          initialValue={verifiedConsignmentDetail?.originDetails?.email || ''}
          className={classes.formItem}
        >
          <Input
            className={classes.inputQuantity}
            placeholder="Email"
          />
        </Form.Item>
        {renderFooterPayment()}
      </Modal>
    );
  };

  return (
    <Form className={classes.main} form={form}>
      {showPaymentModal ? renderPaymentModal() : renderModal()}
    </Form>
  );
};

const hocConfig: HocOptions = {
  connectRedux: {
    useRedux: true,
  },
  connectJss: {
    useJss: true,
    styleSheet: PackageOptionsStyles,
  },
  connectRouter: true,
};

export default GenericHoc(hocConfig)(PackagePage);
