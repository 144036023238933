import React from 'react';
import { tabularDropoffStyles } from '@tabularDropoff/tabularDropoff.styles';
import
{
  tableColumns,
  getColumns,
} from '@tabularDropoff/masterData';
import { StylesProps } from '@theme/jss-types';
import { HocOptions } from '@common/generic-hoc.types';
import GenericHoc from '@common/generic-hoc';
import
{
  getCustomInvoice,
  getAuthLetter,
  getShippingLabelUrl,
  getEcnoteUrl,
} from '@network/dropoff.api';
import
{
  DatePicker,
  Pagination,
  Table,
  Dropdown,
  Menu,
  message,
  Button,
  Popover,
} from 'antd';
import {
  InfoCircleOutlined,
  // FilterOutlined,
  PrinterFilled,
} from '@ant-design/icons';
import moment from 'moment';
import { downloadFileData } from '@src/library/utils';
import { bindActionCreators, Dispatch } from 'redux';
import {
  loadDropoffData,
  setdropoffDate,
  setdropoffFromDate,
  handleDropoffPageChange,
  handleDropoffDisplayTableChange,
} from '@src/actions/dropoff-actions';


interface DisplayTableProps extends StylesProps<ReturnType<typeof tabularDropoffStyles>> {
  status : string,
  dropoffFilters: any,
  pagination: any,
  tableData: any,
  loadData : (status:string) => void,
  setdropoffFilterDate : (arr:any) => void,
  setDropoffFilterFromDate: (arr:any) => void,
  handleTabularDropoffPageChange: (page: number) => void,
  handleDropoffDisplayTable: (sorter:any) => void,
}

const DisplayTable = (props: DisplayTableProps): React.ReactElement => {
  const {
    classes,
    status,
    dropoffFilters,
    pagination,
    tableData,
    loadData,
    setdropoffFilterDate,
    setDropoffFilterFromDate,
    handleTabularDropoffPageChange,
    handleDropoffDisplayTable,
  } = props;
  const [activeDateControl, setActiveDateControl] = React.useState<number>(0);
  const [selectedPrintRowKeys, setSelectedPrintRowKeys] = React.useState<any>([]);
  const [selectedReferenceNumberList, setSelectedReferenceNumberList] = React.useState([]);

  React.useEffect(() => {
    loadData(status);
  }, [status, dropoffFilters, pagination]);

  const getScrollWidth = () => {
    const columns = tableColumns;
    return columns.reduce((acc, cur) => {
      return acc + (Number(cur.width) || 150);
    }, 0);
  };
  const getScrollPropToAdd = () => {
    const x = getScrollWidth() + 75;
    return { x, y: '70vh' };
  };

  const setFromDate = (offset:number, isMonth:boolean) => {
    if (offset === activeDateControl) {
      setActiveDateControl(0);
      setDropoffFilterFromDate(moment().format('YYYY-MM-DD'));
    } else if (isMonth) {
      setActiveDateControl(offset);
      setDropoffFilterFromDate(moment().subtract(offset, 'months').format('YYYY-MM-DD'));
    } else {
      setActiveDateControl(offset);
      setDropoffFilterFromDate(moment().subtract(offset - 1, 'd').format('YYYY-MM-DD'));
    }
  };

  const capitalizeFirst = (str:string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getShippingLabel = async (record:any) => {
    const obj = {
      referenceNumbers: [record.reference_number],
      isSmall: false,
    };
    try {
      const response = await getShippingLabelUrl(obj);
      if (response.isSuccess) {
        downloadFileData(response.data, `shipping_label_${record.reference_number}`, '.pdf');
      } else {
        message.error('No valid reference number provided for printing Shipping Label');
      }
    } catch (err:any) {
      message.error(err.message);
    }
  };

  const getEcNoteLabel = async (record:any) => {
    const obj = {
      referenceNumber: record.reference_number,
      showPriceInECNote: true,
      source: 'DROPOFF',
    };
    try {
      const response = await getEcnoteUrl(obj);
      if (response.isSuccess) {
        downloadFileData(response.data, `ECNOTE_${record.reference_number}`, '.pdf');
      } else {
        message.error('No valid reference number provided for printing EC Note');
      }
    } catch (err:any) {
      message.error(err.message);
    }
  };

  const getCustomInvoiceUrl = async (record:any) => {
    const invoice_obj = {
      awbno: record.awb_no ? record.awb_no : null,
    };
    try {
      const response = await getCustomInvoice(invoice_obj);
      downloadFileData(response.data, 'custom_invoice', '.pdf');
    } catch (err:any) {
      message.error(err.message);
    }
  };

  const getAuthorizationLetter = async (record:any) => {
    const auth_obj = {
      awbno: record.awb_no ? record.awb_no : null,
    };
    try {
      const response = await getAuthLetter(auth_obj);
      downloadFileData(response.data, 'authorization_letter', '.pdf');
    } catch (err:any) {
      message.error(err.message);
    }
  };

  const getPrintLabelMenu = (record:any) => {
    return (
      record.is_international
        ? (
          <Menu>
            <Menu.Item key="ecnote" onClick={() => getShippingLabel(record)}>
              Shipping Label
            </Menu.Item>
            <Menu.Item key="custom_invoice" onClick={() => getCustomInvoiceUrl(record)}>
              Custom Invoice
            </Menu.Item>
            <Menu.Item key="authorisation_letter" onClick={() => getAuthorizationLetter(record)}>
              Authorization Letter
            </Menu.Item>
            <Menu.Item key="ecnote" onClick={() => getEcNoteLabel(record)}>
              E-C-Note
            </Menu.Item>
          </Menu>
        )
        : (
          <Menu>
            <Menu.Item key="ecnote" onClick={() => getShippingLabel(record)}>
              Shipping Label
            </Menu.Item>
            <Menu.Item key="ecnote" onClick={() => getEcNoteLabel(record)}>
              E-C-Note
            </Menu.Item>
          </Menu>
        )
    );
  };

  const renderActionDropdown = (record:any) => {
    return (
      <Dropdown
        overlay={getPrintLabelMenu(record)}
        placement="bottomCenter"
      >
        <PrinterFilled className={classes.actionIcon} />
      </Dropdown>
    );
  };

  const rowSelection = {
    selectedPrintRowKeys,
    onChange: (selectedRowKeys:any, selectedRows:any) => {
      const curr_list:any = [];
      setSelectedPrintRowKeys(selectedRowKeys);
      selectedRows?.forEach((elem:any) => {
        curr_list.push(elem.reference_number);
      });
      setSelectedReferenceNumberList(curr_list);
    },
  };

  const getMultipleShippingLabel = async () => {
    const obj = {
      referenceNumbers: selectedReferenceNumberList,
      isSmall: false,
    };
    try {
      const response = await getShippingLabelUrl(obj);
      if (response.isSuccess) {
        downloadFileData(response.data, 'shippingLabel', '.pdf');
      } else {
        message.error('No valid reference number provided for printing Shipping Label');
      }
    } catch (err:any) {
      message.error(err.message);
    }
  };

  const renderPackagingMaterialTableDetails = (data: any) => {
    const columns = [
      {
        title: 'Details',
        dataIndex: 'itemDesc',
        key: 'itemDesc',
      },
      {
        title: 'Quantity',
        dataIndex: 'purchaseQuantity',
        key: 'purchaseQuantity',
      },
      {
        title: 'Price/unit',
        dataIndex: 'priceForEach',
        key: 'priceForEach',
      },
      {
        title: 'Total Price',
        dataIndex: 'price',
        key: 'price',
      },
    ];
    return (
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        style={{
          margin: '0 10px',
        }}
      />
    );
  };

  const renderPackagingMaterialTable = (row: any) => {
    return (
      <Popover
        trigger="click"
        title="Packaging Details"
        content={() => renderPackagingMaterialTableDetails(
          row.packingDetails?.packingMaterial || [],
        )}
      >
        <InfoCircleOutlined />
      </Popover>
    );
  };

  const generateDataColumns = () => {
    const columnData:any = [];
    const finalTableColumns = getColumns(status);
    finalTableColumns.forEach((col) => {
      const itemToPush:any = {};
      itemToPush.title = col.pretty_name;
      itemToPush.dataIndex = col.column_id;
      itemToPush.width = col.width;
      if (col.column_id === 'reference_number') {
        itemToPush.fixed = 'left';
      }
      if (col.column_id === 'action') {
        itemToPush.fixed = 'right';
        itemToPush.align = 'center';
      }
      if (col.column_id === 'dropoff_schedule_time'
       || col.column_id === 'amount' || col.column_id === 'weight') {
        itemToPush.sorter = true;
      }
      itemToPush.render = (x:any, record:any) => {
        switch (col.column_id) {
          case 'amount':
            return record.total_chargeable_amount || x;
          case 'created_date':
            return moment(x).format('YYYY-MM-DD');
          case 'dropoff_schedule_time':
            return x ? moment(Number(x)).format('DD MMM YYYY | h:mm A') : '';
          case 'status':
            return `${capitalizeFirst(x.split('_')[0])}  ${capitalizeFirst(x.split('_')[1])}`;
          case 'action':
            return renderActionDropdown(record);
          case 'event_time':
            return moment(x).format('YYYY-MM-DD');
          case 'dimensions':
            return `${record.width} x ${record.height} x ${record.length}`;
          case 'packingMaterial':
            return renderPackagingMaterialTable(record);
          default:
            return x;
        }
      };
      columnData.push(itemToPush);
    });
    return columnData;
  };

  const fetchDate = (dateArr: string[]) => {
    const date: any = {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    };
    if (dateArr?.length === 2) {
      date.start = dateArr[0];
      date.end = dateArr[1];
    }
    return date;
  };

  const handleDateSelect: any = (dateArr: string[]) => {
    const date = fetchDate(dateArr);
    const date_obj = {
      start: date.start.format('YYYY-MM-DD'),
      end: date.end.format('YYYY-MM-DD'),
    };
    setdropoffFilterDate(date_obj);
  };

  const handlePageChange = (page:number) => {
    handleTabularDropoffPageChange(page);
  };

  const menu = (
    <Menu>
      <Menu.Item
        style={{ fontSize: '12px' }}
        key="ecnote"
        onClick={() => getMultipleShippingLabel()}
      >
        Print Shipping Label
      </Menu.Item>
    </Menu>
  );

  const renderLeftFilter = () => {
    return (
      <div>
        {/* <span className={classes.filterIcon}>
          <FilterOutlined />
          Filter:
        </span> */}
        <span className={activeDateControl === 1 ? 'dateControl activeDate' : 'dateControl'} onClick={() => setFromDate(1, false)}>Today</span>
        <span className={activeDateControl === 7 ? 'dateControl activeDate' : 'dateControl'} onClick={() => setFromDate(7, false)}>Last 7 days</span>
        <span className={activeDateControl === 15 ? 'dateControl activeDate' : 'dateControl'} onClick={() => setFromDate(15, false)}>Last 15 days</span>
        <span className={activeDateControl === 3 ? 'dateControl activeDate' : 'dateControl'} onClick={() => setFromDate(3, true)}>Last 3 months</span>

        <span className="datePickerContainer" onClick={() => setActiveDateControl(0)}>
          <DatePicker.RangePicker
            value={[
              moment(dropoffFilters.fromDate),
              moment(dropoffFilters.toDate),
            ]}
            format="DD/MM/YYYY"
            onChange={handleDateSelect}
          />
        </span>
        { tableData.data?.length && status === 'COMPLETED'
          ? (
            <span>
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                disabled={!selectedPrintRowKeys.length}
              >
                <Button type="primary">Print</Button>
              </Dropdown>
            </span>
          ) : ''}
      </div>
    );
  };

  const renderRightFilter = () => {
    return (
      <div className={classes.rightFilter}>
        {status === 'UPCOMING'
          ? (
            <div className={classes.upcomingCashBooking}>
              Upcoming Cash Bookings:
              {' '}
              {tableData.upcoming_cash_booking || 0}
            </div>
          ) : ''}

        {tableData.data?.length ? <Pagination simple size="small" current={pagination.currentPage} defaultPageSize={pagination.resultPerPage} onChange={handlePageChange} total={tableData.total_result} /> : ''}
      </div>
    );
  };

  const renderFilterTab = () => {
    return (
      <div className={classes.filterTab}>
        {renderLeftFilter()}
        {renderRightFilter()}
      </div>
    );
  };

  const handleTableChange = (_pagination:any, _filters:any, sorter:any) => {
    handleDropoffDisplayTable(sorter);
  };
  return (
    <div>
      {renderFilterTab()}
      <div className={classes.tableContainer}>
        <Table
          loading={tableData.isLoading}
          locale={{ emptyText: 'No Consignments found.' }}
          columns={generateDataColumns()}
          dataSource={tableData.data}
          rowKey={(record: any) => `${record.reference_number}`}
          rowSelection={rowSelection}
          scroll={getScrollPropToAdd()}
          pagination={false}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { dropoffReducer } = state;
  const {
    dropoffFilters,
    pagination,
    organisationId,
    hubCode,
    tableData,
  } = dropoffReducer;

  return {
    dropoffFilters,
    pagination,
    organisationId,
    hubCode,
    tableData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const actions = {
    loadData: loadDropoffData,
    setdropoffFilterDate: setdropoffDate,
    setDropoffFilterFromDate: setdropoffFromDate,
    handleTabularDropoffPageChange: handleDropoffPageChange,
    handleDropoffDisplayTable: handleDropoffDisplayTableChange,
  };
  return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
  connectRedux: {
    useRedux: true,
    mapStateToProps,
    mapDispatchToProps,
  },
  connectJss: {
    useJss: true,
    styleSheet: tabularDropoffStyles,
  },
};

const TabularDropoff = GenericHoc(hocConfig)(DisplayTable);
export default TabularDropoff;
