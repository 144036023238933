import defaulTheme from '@theme/default-theme.json';
import { SET_UI_THEME } from '@reducers/redux-constants';

const themeToSet = defaulTheme;

const uiTheme = (state = themeToSet, action: any) => {
  switch (action.type) {
    case SET_UI_THEME: {
      return {
        ...state,
        ...action.data,
      };
    }

    default: return state;
  }
};

export default uiTheme;
