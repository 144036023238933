import { Routes } from '@library/constants';

export const getForgotPasswordRoute = (): string => {
  const route = `/${Routes.FORGOT}`;
  return route;
};

export const getLoginRoute = (): string => {
  const route = `/${Routes.LOGIN}`;
  return route;
};

export const getDropoffDashboardRoute = (): string => {
  const route = `/${Routes.DROPOFF}`;
  return route;
};
