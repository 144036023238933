import { DropOffOtherDetailsMapping } from '@dropoff/dropoff.types';

export const dropOffPrettyNameMapping:DropOffOtherDetailsMapping = {
  loadType: {
    name: 'CN Type',
  },
  chargeableWeight: {
    name: 'Weight',
  },
  commodityName: {
    name: 'Content Type',
  },
  volume: {
    name: 'Volume',
  },
  serviceType: {
    name: 'Service Type',
  },
  weightMismatchVolume: {
    name: 'Weight Mismatch Volume',
  },
  weightMismatchWeight: {
    name: 'Weight Mismatch Weight',
  },
};

export const dropOffOtherDetailsMapping:DropOffOtherDetailsMapping = {
  originPincode: {
    name: 'Origin Pincode',
  },
  destinationPincode: {
    name: 'Destination Pincode',
  },
  creationDetails: {
    name: 'Booking Date',
  },
};
