import { ONLINE_BOOKING_API_URL } from '@src/globals';
import {
  LOGIN,
} from '@network/api.constants';
import { POST } from '@network/api-hander.service';

export const login = async (body: {
  username: string;
  password: string;
}) => {
  return POST(`${ONLINE_BOOKING_API_URL}${LOGIN}`, body);
};
