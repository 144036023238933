import * as React from 'react';
import Loader from '@components/common/Loader';
import { Switch, Route } from 'react-router-dom';
import { HocOptions } from '@common/generic-hoc.types';
import GenericHoc from '@common/generic-hoc';
import { getDropoffDashboardRoute } from '@routing/routing-helper';
import DropoffPage from '@components/dropoff/dropoff';

const AutheticatedRoutes = () => {
  return (
    <React.Suspense fallback={<Loader zIndex={5} />}>
      <Switch>
        <Route exact path={getDropoffDashboardRoute()} render={() => <DropoffPage />} />
        <Route path="/" render={() => <DropoffPage />} />
      </Switch>
    </React.Suspense>
  );
};

const hocConfig: HocOptions = {
  connectRedux: {
    useRedux: true,
  },
};
const Authenticated = GenericHoc(hocConfig)(AutheticatedRoutes);
export default Authenticated;
