export const tabularDropoffStyles = () => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    borderTop: '2px solid #f0f0f0',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
      borderBottom: '4px solid #f0f0f0',
    },
    '& .ant-table-thead > tr > th': {
      fontWeight: 600,
    },
    fontFamily: 'Noto Sans',
  },
  filterTab: {
    backgroundColor: 'white',
    border: '1px solid #f0f0f0',
    borderTop: '0px',
    fontSize: '11px',
    padding: '10px 5px',
    fontFamily: 'Noto Sans',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .dateControl': {
      color: 'rgba(0, 0, 0, 0.85)',
      background: 'white',
      border: '2px solid #f0f0f0',
      marginLeft: '8px',
      borderRadius: '15px',
      padding: '5px',
      cursor: 'pointer',
    },
    '& .activeDate': {
      color: 'white',
      background: '#0E2C53',
    },
    '& .datePickerContainer': {
      margin: '10px',
      paddingLeft: '10px',
      borderLeft: '2px solid #f0f0f0',
    },
    '& .ant-picker-range': {
      borderRadius: '20px',
      border: '2px solid #f0f0f0',
      width: '228px',
    },
    '& .ant-pagination': {
      // display: 'inline',
      alignItems: 'center',
    },
    '& .ant-btn-primary': {
      background: '#0E2C53',
      width: '70px',
      border: '1px solid #0E2C53',
      borderRadius: '4px',
      fontSize: '12px',
      color: '#fff',
    },
  },
  filterIcon: {
    fontSize: 15,
  },
  upcomingCashBooking: {
    borderRadius: '20px',
    border: '2px solid #f0f0f0',
    padding: '5px',
    fontSize: 13,
  },
  rightFilter: {
    alignItems: 'center',
    display: 'flex',
  },
  actionIcon: {
    fontSize: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  trackInput: {
    width: '30%',
  },
});
