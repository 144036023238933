import React from 'react';
import Routing from '@routing/router-outlet';
import Navbar from '@common/Navbar';
import authHelper from '@auth/auth-helper';
import { CssType } from '@theme/jss-types';
import { HocOptions } from '@common/generic-hoc.types';
import { MIN_WIDTH } from '@src/globals';
import 'antd/dist/antd.css';
import '@src/App.css';
import GenericHoc from '@common/generic-hoc';

const styles: CssType = {
  mainDiv: {
    height: '100vh',
    backgroundColor: '#EDE8DE',
    minWidth: MIN_WIDTH,
  },
  contentDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
};

const App = (props: any) => {
  const {
    classes,
  } = props;

  const authenticated = authHelper.isAuthenticated();

  const renderNavbar = () => {
    if (!authenticated) {
      return null;
    }
    return <Navbar pageTitle="Drop Off Portal" />;
  };

  const renderRoutes = () => {
    return (
      <Routing />
    );
  };

  return (
    <div
      className={classes.mainDiv}
    >
      {renderNavbar()}
      <div>
        {renderRoutes()}
      </div>
    </div>
  );
};

const hocConfig: HocOptions = {
  connectRedux: {
    useRedux: true,
  },
  connectJss: {
    useJss: true,
    styleSheet: styles,
  },
  connectRouter: true,
  connectTranslession: false,
};
export default GenericHoc(hocConfig)(App);
