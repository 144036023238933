const PackageOptionsStyles = () => ({
  main: {
    '& .ant-input-number-input': {
      borderRadius: 4,
      width: '100%',
    },
    '.anticon svg': {
      color: '#eeeeee',
    },
    '& .ant-form-item': {
      margin: 0,
      padding: 0,
    },
  },
  modal: {
  },
  quantityRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  selectLabel: {
    font: 'Open Sans Regular',
    fontSize: 10,
    marginBottom: 6,
    letter: 'normal',
  },
  itemName: {
    font: 'Open Sans SemiBold',
    fontSize: 10,
  },
  inputQuantityCol: {
    padding: '0 4px',
  },
  inputQuantity: {
    width: '100%',
    margin: '0 4px',
    borderRadius: 4,
  },
  tableHeader: {
    fontSize: 10,
  },
  deleteOptionIcon: {
    alignItems: 'center',
    height: 30,
  },
  deleteIcon: {
    display: 'flex',
    margin: '0 8px',
    alignItems: 'center',
  },
  footer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  acceptButton: {
    backgroundColor: '#0E2C53 !important',
    textAlign: 'center',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  additionalAmount: {
    height: 32,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  tableHeaderRowQuantity: {
    marginTop: 5,
    marginLeft: 10,
    marginBottom: -8,
  },
  tableHeaderRowPrice: {
    marginTop: 5,
    marginBottom: -8,
  },
  formItem: {
    margin: 0,
    padding: 0,
  },
  cancelButton: {
    color: '#0E2C53 !important',
    textAlign: 'center',
    '&:hover': {
      color: '#0E2C53 !important',
    },
  },
});

export default PackageOptionsStyles;
