import * as React from 'react';
import DocumentTitle from 'react-document-title';
import withStyles from 'react-jss';
import { NAVBAR_HEIGHT } from '@src/globals';
import { StylesProps, ThemeType } from '@theme/jss-types';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Button, Dropdown, Menu, Input, Modal, Collapse,
} from 'antd';
import authHelper from '@auth/auth-helper';
import { handleDropoffSearch } from '@src/actions/dropoff-actions';
import { faqAndAnswers } from '@tabularDropoff/masterData';

const styles = (theme: ThemeType) => ({
  mainDiv: {
    width: '100%',
    height: `${NAVBAR_HEIGHT}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    padding: '4px 16px 4px 0',
    border: '1px solid #EFEFEF',
    color: 'black',
    backgroundColor: theme.primaryColor,
  },
  logoBox: {
    fontSize: 32,
    fontWeight: 600,
    height: '100%',
    alignSelf: 'center',
    color: '#FFFFFF',
    width: '50%',
    marginLeft: '22px',
    fontFamily: 'Noto Sans',
  },
  navbarHeading: {
    marginLeft: '30px',
  },
  rightBox: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .ant-input-affix-wrapper': {
      border: 'none',
      borderRadius: 14,
      padding: '2.5px 10px',
      height: '2.3em',
    },
    '& .ant-input-affix-wrapper > input.ant-input': {
      fontSize: 12,
    },
    '& .ant-btn-link': {
      color: '#FFF',
      fontSize: 12,
      fontWeight: 600,
    },
    '& .ant-input-group-addon > button.ant-btn': {
      border: 'none',
    },
  },
  trackInput: {
    width: '50%',
    paddingRight: 100,
    fontFamily: 'Noto Sans',
  },
  inputs: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: '36%',
    margin: '0 2px',
  },
  profile: {
    height: 30,
    width: 32,
    borderRadius: 29,
    backgroundColor: theme.secondryColor,
    cursor: 'pointer',
    display: 'inline',
    alignItems: 'center',
    padding: 11,
    paddingLeft: 14,
    paddingRight: 14,
    marginLeft: 0,
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Noto Sans',
  },
  username: {
    width: 'auto',
    whiteSpace: 'nowrap',
    marginLeft: 0,
    paddingLeft: 0,
  },
  usernameDisplay: {
    marginLeft: 50,
    fontFamily: 'Noto Sans',
  },
  img: {
    fontSize: 32,
    marginRight: '4%',
    cursor: 'pointer',
    marginBottom: 6,
  },
  FaqSearchInput: {
    marginTop: 25,
    marginBottom: 25,
    borderRadius: 15,
    marginRight: '5%',
    marginLeft: '5%',
    borderColor: 'black',
    height: 45,
    width: '90%',
    alignItems: 'center',
    fontFamily: 'Noto Sans',
  },
  faqPanel: {
    marginTop: 10,
    marginRight: '7',
    marginLeft: '7%',
    width: '86%',
    borderColor: 'black',
    fontWeight: 'bold',
    fontFamily: 'Noto Sans',
    fontSize: 20,
  },
  faqCollapse: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    fontFamily: 'Noto Sans',
  },
  faqAnswers: {
    fontWeight: 'normal',
  },
  faqModalHeading: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 40,
    paddingTop: 20,
    paddingLeft: 20,
    backgroundColor: 'white',
    fontFamily: 'Noto Sans',
  },
  finalModalBlock: {
    paddingBottom: 580,
    margin: 0,
    backgroundColor: 'white',
  },
  modalclass: {
    '& .ant-modal-content': {
      height: '0vh',
      margin: 0,
      top: 0,
    },
  },
  mainModal: {
    backgroundColor: 'white',
  },
});

interface NavbarProps extends StylesProps<ReturnType<typeof styles>> {
  pageTitle: string;
  handleDropoffTabularSearch: (query: string) => void,
}

const Navbar = (props: NavbarProps) => {
  const { pageTitle, classes, handleDropoffTabularSearch } = props;
  const {
    username,
    logoUrl,
  } = authHelper.getUsernameAndLogo();

  // const [queryString, setQueryString] = React.useState<string>();

  const handleConsignmentSearch = (val: string, e: any) => {
    // eslint-disable-next-line no-console
    console.log(e);
    handleDropoffTabularSearch(val);
  };

  const handleInputOnChange = (e: any) => {
    if (e.target.value === '') {
      handleDropoffTabularSearch('');
    }
  };

  const [isFaqModalOpen, setIsFaqModalOpen] = React.useState(false);

  const showFaqModal = () => {
    setIsFaqModalOpen(true);
  };

  const handleFaqOk = () => {
    setIsFaqModalOpen(false);
  };

  const handleFaqCancel = () => {
    setIsFaqModalOpen(false);
  };

  const RenderFaqModal = () => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const { Panel } = Collapse;
    return (
      <div className={classes.mainModal}>
        <div className={classes.faqModalHeading}>Frequently Asked Questions</div>
        <Input
          type="text"
          placeholder="Search Frequently Asked Questions"
          className={classes.FaqSearchInput}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
        <Collapse className={classes.faqCollapse}>
          {
            faqAndAnswers.filter((val) => {
              if (val.ques.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val;
              }
              return null;
            }).map((i) => {
              return (
                <Panel header={i.ques} key={i.key} className={classes.faqPanel}>
                  <p className={classes.faqAnswers}>{i.ans}</p>
                </Panel>
              );
            })
          }
        </Collapse>
        <div className={classes.finalModalBlock} />
      </div>
    );
  };

  const menu = (
    <Menu
      style={{ fontFamily: 'Noto Sans' }}
    >
      <Menu.Item
        key="faq"
        icon={<QuestionCircleOutlined />}
        onClick={showFaqModal}
      >
        FAQs
      </Menu.Item>
      <Modal
        bodyStyle={{ height: 1900, padding: '0' }}
        open={isFaqModalOpen}
        width={2950}
        style={{ top: 10 }}
        onOk={handleFaqOk}
        onCancel={handleFaqCancel}
        className={classes.modalclass}
        closeIcon={(
          <CloseOutlined
            style={{
              color: 'black',
              fontSize: '40px',
              margin: '10px 10px 10px 0px',
            }}
          />
        )}
        footer={null}
      >
        {RenderFaqModal()}
      </Modal>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => authHelper.logoutUser()}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderDropdown = () => {
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        className={classes.username}
      >
        <div>
          <Button type="link" className={classes.usernameDisplay}>
            {username}
            <DownOutlined />
          </Button>
          <div className={classes.profile}>{username && username[0].toUpperCase()}</div>
        </div>
      </Dropdown>
    );
  };

  // Render Input
  const renderInput = () => {
    return (
      <Input.Search
        style={{ height: '33px' }}
        allowClear
        className={classes.trackInput}
        onChange={(e: any) => handleInputOnChange(e)}
        onSearch={(val: string, e: any) => handleConsignmentSearch(val, e)}
        type="search"
        placeholder="Search Consignment"
      />
    );
  };

  return (
    <div className={classes.mainDiv}>
      <DocumentTitle title={pageTitle} />
      <div className={classes.logoBox}>
        <img
          alt="logo-url"
          src={logoUrl as string}
          style={{
            height: '98%',
          }}
        />
        <label className={classes.navbarHeading}>DropOff Portal</label>
      </div>
      <div className={classes.rightBox}>
        {renderInput()}
        {renderDropdown()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { dropoffReducer } = state;
  const { dropoffFilters } = dropoffReducer;
  return {
    dropoffFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const actions = {
    handleDropoffTabularSearch: handleDropoffSearch,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withStyles(styles, { injectTheme: true })(Navbar));
