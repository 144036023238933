const dropoffStyles = () => ({
  main: {
    height: '100vh',
  },
  contentDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#EDE8DE',
    overflow: 'hidden',
    marginBotton: 10,
  },
  dropOffMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
  },
  leftPart: {
    width: 380,
    height: '100%',
  },
  rightPart: {
    backgroundColor: '#EDE8DE',
    height: '100%',
    marginTop: '16px',
    width: 'calc(100% - 402px)',
    '& .ant-tabs-top > .ant-tabs-nav': {
      margin: '0px',
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: '#0E2C53 !important',
      fontWeight: 'bolder',
    },
    fontFamily: 'Noto Sans',
  },
  dropOffRequestForm: {
    width: '351px',
    height: '268px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    margin: '16px 5px 6px 22px',
  },
  droppoffHeading: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#262626',
    paddingLeft: '32px',
    paddingTop: '32px',
    lineHeight: '16px',
    align: 'right',
    fontFamily: 'Noto Sans',
  },
  dropoffForm: {
    marginTop: '20px',
    '& .ant-input:placeholder-shown': {
      fontSize: '10px',
    },
    '& .ant-form-item': {
      marginBottom: '0px',
    },
    '& .ant-form-item-label > label': {
      marginLeft: '30px',
      fontSize: '14px',
      fontFamily: 'Noto Sans',
    },
  },
  formInput: {
    width: '287px',
    fontSize: '14px',
    textAlign: 'left',
    height: '28px',
    border: '1px solid #999999',
    borderRadius: '4px',
    margin: '0px 32px 0px 32px',
  },
  inputHeading: {
    fontSize: '10px',
    color: '#111111',
    lineHeight: '10px',
    letterSpacing: 'normal',
    padding: '16px 0px 6px 32px',
  },
  submitButton: {
    backgroundColor: '#0E2C53 !important',
    width: '287px',
    height: '32px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '22px 32px 10px 32px',
    fontFamily: 'Noto Sans',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  formLabel: {
    '& .ant-form-item-explain': {
      height: '4px',
      minHeight: '4px',
      marginLeft: '35px',
      textAlign: 'left',
      fontFamily: 'Noto Sans',
      fontSize: '10px',
      fontWeight: 600,
    },
    '& .ant-form-item-control': {
      minWidth: '100% !important',
    },
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  dropOffDetailsForm: {
    width: '351px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    margin: '6px 5px 15px 22px',
    textAlign: 'center',
    fontFamily: 'Noto Sans',
    '& .ant-form-item': {
      margin: 0,
      padding: 0,
    },
  },
  consignmentNumberHeading: {
    padding: '17px 30px 9px 32px',
    fontSize: '12px',
    color: '#666666',
    letterSpacing: 'normal',
    lineHeight: '17px',
    fontWeight: 600,
    fontFamily: 'Noto Sans',
  },
  consignmentNumberPaymentStatus: {
    padding: '5px 30px 3px 32px',
    fontSize: '12px',
    color: '#666666',
    letterSpacing: 'normal',
    lineHeight: '17px',
    fontWeight: 600,
    fontFamily: 'Noto Sans',
  },
  lineBreakStyle: {
    width: '325px',
    position: 'center',
    color: '#D9D9D9',
    opacity: '61%',
    border: '1px solid #D9D9D9',
  },
  mainDiv: {
    padding: '22px 0px 0px 32px',
  },
  detailsHeading: {
    fontSize: '16px',
    color: '#262626',
    letterSpacing: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'left',
  },
  detailsValue: {
    fontSize: '10px',
    color: '#111111',
    letterSpacing: 'normal',
    lineHeight: '10px',
    textAlign: 'left',
    paddingTop: '12px',
  },
  logoStyle: {
    marginTop: '97px',
    width: '14%',
  },
  logoSuccessStyle: {
    marginTop: '97px',
    width: '14%',
  },
  errorMessageText: {
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600,
    letterSpacing: 'normal',
    color: '#111111',
    textAlign: 'center',
    marginTop: '10px',
  },
  okayButton: {
    backgroundColor: '#0E2C53 !important',
    width: '317px',
    height: '32px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '80px 32px 10px 32px',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
  },
  packageMain: {
    padding: '20px 20px 22px 32px',
  },
  packageDetHeading: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#262626',
    lineHeight: '14px',
    textAlign: 'left',
    height: '120px',
  },
  packageFieldMain: {
    marginTop: '10px',
  },
  customRowStyle: {
    height: '70px',
  },
  packageDetField: {
    color: '#666666',
    fontSize: '10px',
    lineHeight: '10px',
    textAlign: 'center',
    paddingTop: '7px',
  },
  packageDetVal: {
    fontSize: '10px',
    lineHeight: '10px',
    color: '#111111',
    letterSpacing: 'normal',
    textAlign: 'center',
    paddingTop: '3px',
  },
  columnStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '20px',
  },
  otherDetailsMain: {
    padding: '17px 35px 5px 32px',
  },
  otherDetailsRow: {
    marginBottom: '16px',
  },
  packingDetailsTitleRow: {
    margin: '3px 3px 3px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  packingDetailsTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#262626',
    lineHeight: '14px',
    textAlign: 'left',
    marginLeft: 10,
  },
  awaitPaymentDivStylePacking: {
    width: '100px',
    height: '22px',
    background: '#EF4848',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
  },
  packingDetails: {
    marginBottom: 10,
    padding: '0 10px 0 20px',
    '& .ant-table-thead > tr > th': {
      fontSize: 10,
      color: '#666666',
      marginBottom: '0px !important',
      paddingBottom: '0px !important',
      border: 'none: !important',
      background: 'none',
    },
    '& .ant-table-tbody > tr > td': {
      fontSize: 10,
      fontWeight: 600,
      color: '#111111',
      marginBottom: '0px !important',
      paddingBottom: '0px !important',
      border: 'none: !important',
      background: 'none !important',
    },
    '& .ant-table-footer': {
      display: 'flex',
      fontSize: 11,
      fontWeight: 600,
    },
  },
  totalCostTitle: {
    width: '80%',
    textAlign: 'left',
  },
  totalCostValue: {
    width: '20%',
    textAlign: 'left',
    paddingLeft: 12,
  },
  otherDetHeaders: {
    color: '#666666',
    fontSize: '10px',
    lineHeight: '10px',
    textAlign: 'left',
    paddingBottom: '10px',
  },
  otherDetVal: {
    fontSize: '10px',
    lineHeight: '10px',
    color: '#111111',
    letterSpacing: 'normal',
    fontWeight: 600,
    textAlign: 'left',
  },
  paymentDivStyle: {
    width: '100px',
    height: '22px',
    background: '#C3FFCA',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
  },
  awaitPaymentDivStyle: {
    width: '100px',
    height: '22px',
    background: '#EF4848',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
    paddingTop: 2,
  },
  awaitConsignmentPaymentDivStyle: {
    width: '160px',
    height: '22px',
    background: '#EF4848',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
    paddingTop: 2,
  },
  sucessPaymentDivStyle: {
    width: '100px',
    height: '22px',
    background: '#C3FFCA',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
    paddingTop: 2,
  },
  pendingPaymentDivStyle: {
    height: '22px',
    background: '#EF4848',
    borderRadius: '4px',
    border: '1px solid #C3FFCA',
    paddingLeft: 5,
    paddingRight: 5,
  },
  paymentStatusRefreshButtonAwaited: {
    backgroundColor: '#EF4848',
    textAlign: 'center',
    paddingBottom: -2,
    paddingRight: 2,
    fontSize: '13px',
    height: 20,
    marginRight: '-40px',
    marginLeft: '7px',
    cursor: 'pointer',
    '&:hover': {
      color: '#0E2C53 !important',
    },
  },
  paymentStatusRefreshButtonSucess: {
    backgroundColor: '#C3FFCA',
    textAlign: 'center',
    paddingBottom: -2,
    paddingRight: 2,
    fontSize: '13px',
    height: 20,
    marginRight: '-40px',
    marginLeft: '7px',
    cursor: 'pointer',
    '&:hover': {
      color: '#0E2C53 !important',
    },
  },
  paymentFontStyle: {
    fontSize: '10px',
    lineHeight: '14px',
    color: '#111111',
    padding: '4px 19px 4px 18px',
    fontWeight: 600,
  },
  paymentStatusStyle: {
    fontSize: '10px',
    lineHeight: '14px',
    color: '#111111',
    padding: '1px 26px 9px 13px',
    marginLeft: '-10px',
    fontWeight: 600,
  },
  pendingPaymentStatusStyle: {
    fontSize: '10px',
    lineHeight: '14px',
    color: '#fff',
    fontWeight: 600,
  },
  dropOffDetailsMain: {
    margin: '3px 3px 3px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropOffDetailsWithPaymentStatus: {
    margin: '3px 3px 3px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  collectionButtonDiv: {
    margin: '10px 32px 10px 32px',
  },
  collectionButton: {
    backgroundColor: '#0E2C53 !important',
    width: '287px',
    height: '27px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  weightmismatchSubmitButton: {
    backgroundColor: '#0E2C53 !important',
    width: '150px',
    height: '35px',
    margin: '20px 10px 0px 0px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  collectCashSubmitButton: {
    backgroundColor: '#0E2C53 !important',
    width: '150px',
    height: '35px',
    margin: '20px 10px 0px 0px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  submitPaymentLinkButton: {
    backgroundColor: 'transparent !important',
    width: '150px',
    height: '35px',
    margin: '20px 25px 0px 0px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
    padding: '5px 0',
  },
  pendingAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '4px',
    margin: '5px 0',
    padding: '5px',
    border: '1px solid #9397B2',
    backgroundColor: '#F5F8FE !important',
  },
  cashModal: {
    '& .ant-modal-content': {
      backgroundColor: 'white !important',
    },
    '& .ant-alert-success': {
      backgroundColor: 'white !important',
      border: 'none',
    },
  },
  trackInput: {
    width: '30%',
  },
  resendDropoffCode: {
    marginLeft: '40px',
    paddingRight: '180px',
    color: '#0E2C53',
    cursor: 'pointer',
    fontSize: '10px',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'right',
    margin: '20px 0px 0px 0px',
  },
  modal: {
    textAlign: 'center',
  },
  weightmismatchButton: {
    backgroundColor: '#0E2C53 !important',
    width: '287px',
    height: '27px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '5px 32px 10px 32px',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    '&:hover': {
      backgroundColor: '#0E2C53 !important',
    },
    color: '#fff !important',
  },
  disabledButton: {
    backgroundColor: 'grey !important',
    '&:hover': {
      backgroundColor: 'grey !important',
    },
  },
  differentailCost: {
    textAlign: 'center',
    margin: '22px 32px 7px 32px',
    fontSize: '15px',
    color: '#fc0303',
  },
  weightmismatchPaymentStatus: {
    textAlign: 'center',
    margin: '22px 32px 7px 32px',
    fontSize: '15px',
    color: '#018f25',
  },
  disabledCollectionButton: {
    backgroundColor: '#0E2C53 !important',
    width: '287px',
    height: '27px',
    borderRadius: '4px',
    textAlign: 'center',
    paddingTop: '3px',
    margin: '0px 32px 7px 32px',
    fontSize: '12px',
    border: '1px solid #0E2C53',
    color: '#fff !important',
    cursor: 'no-drop',
  },
  communicationModalFields: {
    display: 'flex',
  },
  communicationModalInputFields: {
    marginLeft: '20px',
  },
  coummunicationModalErrHandling: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  communicationEmailField: {
    display: 'flex',
    marginTop: '10px',
  },
});

export {
  dropoffStyles,
};
