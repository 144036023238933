import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from '@reducers/redux-store';
import thunk from 'redux-thunk';

const configureStore = (preloadedState: any) => createStore(
  rootReducer,
  preloadedState,
  compose(
    applyMiddleware(thunk),
  ),
);

export default configureStore;
