import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '@theme/jss-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const styles = {
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: -1,
  },
};

interface LoaderProps extends StylesProps<typeof styles> {
  zIndex?: number;
  top?: number;
  size?: string;
}
const Loader = (props: LoaderProps) => {
  const {
    classes, zIndex, top, size,
  } = props;
  return (
    <div
      className={classes.loaderContainer}
      style={{
        zIndex: zIndex || -1,
        top: top || 0,
      }}
    >
      <Spin
        indicator={(
          <LoadingOutlined
            style={{ fontSize: size || 40 }}
            spin
          />
        )}
      />
    </div>
  );
};

export default withStyles(styles)(Loader);
