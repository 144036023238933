import * as React from 'react';
import { HocOptions } from '@common/generic-hoc.types';
import { StylesProps } from '@theme/jss-types';
import { RouteChildrenProps } from 'react-router';
import { dropoffStyles } from '@dropoff/dropoff.styles';
import GenericHoc from '@common/generic-hoc';
import CustomTabs from '@components/common/Tabs';
import TabularDropoff from '@tabularDropoff/tabularDropoff';
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Modal,
  Table,
  Tooltip,
  Spin,
  Alert,
} from 'antd';
import {
  dropoff_invalid,
  dropoff_success,
} from '@assets/index';
import {
  verifyDropoff,
  resendSMS,
  weightMismatchPaymentLink,
  getWeightMismatchSubmit,
  weightMismatchValues,
}
  from '@network/dropoff.api';
import {
  DropOffPayload,
  PackageDetailsPayload,
} from '@dropoff/dropoff.types';
import {
  dropOffPrettyNameMapping,
  dropOffOtherDetailsMapping,
} from '@dropoff/dropoff.constants';
import
{
  weightMismatchColumns,
  collectionCompleteDiableText,
} from '@tabularDropoff/masterData';
import moment from 'moment';
import lodash from 'lodash';
import FormItem from 'antd/es/form/FormItem';
import { EditOutlined, RedoOutlined } from '@ant-design/icons';
import PackageOption from './package-option/package';
import Column from 'antd/lib/table/Column';

interface DropoffProps
  extends StylesProps<ReturnType<typeof dropoffStyles>>, RouteChildrenProps {
}
const { useState } = React;

const DropoffPage = (props : DropoffProps) => {
  const {
    classes,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [collectionLoading, setCollectionLoading] = useState<boolean>(false);
  const [showValidDropoff, setShowValidDropoff] = useState<boolean>(false);
  const [showInvalidDropoff, setShowInvalidDropoff] = useState<boolean>(false);
  const [showDropoffDetails, setShowDropoffDetails] = useState<boolean>(false);
  const [ShowDifferntialCost, setShowDifferntialCost] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [dropOffFormData, setDropOffFormData] = useState<Record<string, string| null>>({
    referenceNumber: null,
    validationCode: null,
  });
  const [dropOffDetailsData, setDropOffDetailsData] = useState<any>({});
  const [dropOffMessage, setDropOffMessage] = useState<string>('');
  const [form] = Form.useForm();
  const [ShowPaymentStatus, SetShowPaymentStatus] = useState<string>('none');
  const [amountPending, setPaymentAmountPending] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPackageModalOpen, setIsPackageModalOpen] = useState<boolean>(false);
  const [verifiedConsignmentDetail, setVerifiedConsignmentDetails] = useState<any>(null);
  const [lastDropOffCode, setLastDropOffCode] = useState<any>(null);
  const [isCollectCashModalOpen, setCollectCashModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingWightmmismatch, seteditingWeightmismatch] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [consignmentEmail, setConsignmentEmail] = useState<string>('');
  const [paymentLinkButtonText, setPaymentLinkButtonText] = useState<string>('Send Payment Link');
  const [communicationModalTitle, setcommunicationModalTitle] = useState<string>('WEIGHT MISMATCH');
  const [isCashToOnlineDropoff, setIsCashToOnlineDropoff] = useState<boolean>(false);
  const [isDropoffPaymentLinkSent, setIsDropoffPaymentLinkSent] = useState<boolean>(false);
  const [consignmentPaymentStatus, setConsignmentPaymentStatus] = useState<string>('none');
  const [visibleSubmitButton, setVisibleSubmitButton] = useState<boolean>(false);
  const [isWeightMismatchDisabled, setIsWeightMismatchDisabled] = useState<boolean>(false);
  const [differentialCost, setDifferentialCost] = useState<any>(0);
  const [isPaymentAwaited, setisPaymentAwaited] = useState<boolean>(false);
  const [visiblePaymentStatus, setVisiblePaymentStatus] = useState<boolean>(true);
  const [isCommunicationModal, setIsCommunicationModal] = useState<boolean>(false);
  const [consignmentphone, setconsignmentphone] = useState<string>('');
  const [isValidPhone, setValidPhone] = useState<boolean>(true);
  const [isValidEmail, setValidEmail] = useState<boolean>(true);
  const [isInternationalConsignment, setIsInternational] = useState<boolean>(true);
  const [refNum, setRefNum] = useState<string>('');
  const [packagingAmount, setPackagingAmount] = useState<number>(0);
  const [tariffCharges, setTariffCharges] = useState<number>(0);

  const renderConsignmentNumber = () => {
    return (
      <Form.Item
        name="referencenumber"
        className={classes.formLabel}
        label="Enter AWB / Reference No."
        colon={false}
        rules={[{
          required: true,
          message: 'AWB / Reference No. number is required',
        }]}
      >
        <Input
          className={classes.formInput}
          autoComplete="off"
          placeholder="Enter AWB / Reference No. number "
        />
      </Form.Item>
    );
  };

  const resendDropoffSMS = async () => {
    const referenceNumber = form.getFieldsValue().referencenumber;
    const username = window.localStorage.getItem('username');

    setLoading(true);
    const res = await resendSMS({ referenceNumber, username });
    if (res.isSuccess) {
      message.success('Drop-off Code has been successfully sent');
    } else {
      message.error(res.errorMessage);
    }
    setLoading(false);
  };

  const renderDropoffCode = () => {
    return (
      <Form.Item
        name="validationcode"
        label={(
          <div>
            <span>Enter Drop Off Code</span>
            <span className={classes.resendDropoffCode} onClick={resendDropoffSMS}>
              Resend Drop-off Code
            </span>
          </div>
        )}
        className={classes.formLabel}
        colon={false}
        rules={[{
          required: true,
          message: 'Drop off code is required',
        }]}
      >
        <Input
          className={classes.formInput}
          autoComplete="off"
          placeholder="Enter Drop Off Code"
        />
      </Form.Item>
    );
  };

  const renderSubmitButton = () => {
    return (
      <Form.Item>
        <Button
          className={classes.submitButton}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Check
        </Button>
      </Form.Item>
    );
  };

  const setDropOffComponent = (dropoff:boolean, valid:boolean, invalid:boolean) => {
    setShowDropoffDetails(dropoff);
    setShowValidDropoff(valid);
    setShowInvalidDropoff(invalid);
  };

  const handleVerifyDropoff = async (refNo: string, validationcode: string,
    isComp: boolean, isCashPayment : boolean, isInternational : boolean,
    sendDropoffPaymentLink : boolean) => {
    const storage = window.localStorage;

    if (!isComp) {
      setDropOffFormData({
        ...dropOffFormData,
        referenceNumber: refNo,
        validationCode: validationcode,
      });
    }

    const body:DropOffPayload = {
      referenceNumber: refNo,
      validationCode: validationcode,
      hubCode: storage.getItem('hubCode') || '',
      hubType: storage.getItem('source') || '',
      isCompletionRequest: isComp || false,
      isCashPayment: isCashPayment || false,
      isInternational: isInternational || false,
      phone: consignmentphone,
      email: consignmentEmail,
      sendDropoffPaymentLink: sendDropoffPaymentLink || false,
    };

    const res = await verifyDropoff(body);

    if (res.isSuccess) {
      setLoading(false);
      setCollectionLoading(false);
      if (!res.data.isSuccess && res.data.message) {
        setDropOffMessage(res.data.message);
        setDropOffComponent(false, false, true);
      } else if (res.data && res.data.details && res.data.details.consignmentDetails && res.data.details.consignmentDetails.booking_status === 'cancelled') {
        setDropOffMessage('The Drop Off has been Cancelled');
        setDropOffComponent(false, false, true);
      } else {
        const ConsignmentDetails = res?.data?.details?.consignmentDetails;
        const PaymentDetails = res?.data?.details?.paymentDetails;
        setRefNum(res?.data?.referenceNumber ?? '');
        if (PaymentDetails) {
          if (PaymentDetails?.paymentStatus === 'payment_awaited' && PaymentDetails?.modeOfPayment === 'CASH') {
            SetShowPaymentStatus('PENDING');
            setConsignmentPaymentStatus('PENDING');
            setPaymentAmountPending(PaymentDetails?.amountToPay);
            setPackagingAmount(PaymentDetails?.packagingCharges);
            setTariffCharges(PaymentDetails?.tariffCharges);
            setDifferentialCost(PaymentDetails?.weightMismatchDiffPrice);
          }
        }
        if (ConsignmentDetails) {
          setVerifiedConsignmentDetails(ConsignmentDetails);
          if (PaymentDetails?.modeOfPayment !== 'CASH') {
            if (ConsignmentDetails?.isCashToOnlineDropoff) {
              SetShowPaymentStatus('PENDING');
              setPaymentAmountPending(PaymentDetails?.amountToPay);
              setIsDropoffPaymentLinkSent(true);
              if (ConsignmentDetails?.proceedBooking) {
                setConsignmentPaymentStatus('SUCCESS');
              } else if (!ConsignmentDetails?.proceedBooking) {
                setConsignmentPaymentStatus('AWAITED');
              }
            } else if (ConsignmentDetails?.isWeightMismatch) {
              if (ConsignmentDetails?.proceedBooking) {
                SetShowPaymentStatus('SUCCESS');
              } else if (!ConsignmentDetails?.proceedBooking) {
                SetShowPaymentStatus('AWAITED');
              }
            }
          }

          setconsignmentphone(ConsignmentDetails?.originDetails?.phone);
          setConsignmentEmail(ConsignmentDetails?.originDetails?.email);
        }
        setIsInternational(ConsignmentDetails?.isInternational ?? false);
        const dropoff_data = lodash.size(res.data.details);
        if (dropoff_data > 0) {
          setDropOffDetailsData(res.data.details);
          setDropOffComponent(true, false, false);
        } else {
          setDropOffMessage(res.data.message);
          setDropOffComponent(false, true, false);
        }
      }
    } else {
      message.error(res.errorMessage);
      setLoading(false);
      setCollectionLoading(false);
    }
  };

  const handleDropoffSubmit = async (values: { referencenumber: string,
    validationcode: string, isInternational : boolean }) => {
    setShowDropoffDetails(false);
    const { referencenumber, validationcode, isInternational } = values;
    SetShowPaymentStatus('none');
    setConsignmentPaymentStatus('none');
    setLastDropOffCode(validationcode);
    setShowDifferntialCost(false);
    setisPaymentAwaited(false);
    setIsDropoffPaymentLinkSent(false);
    setLoading(true);
    handleVerifyDropoff(referencenumber as string, validationcode as string,
      false, false, isInternational, false);
    form.resetFields();
  };

  const renderDropOffForm = () => {
    return (
      <div className={classes.dropOffRequestForm}>
        <div className={classes.droppoffHeading}>
          Drop-Off Details
        </div>
        <div className={classes.dropoffForm}>
          <Form
            form={form}
            wrapperCol={{ span: 20 }}
            onFinish={handleDropoffSubmit}
          >
            {renderConsignmentNumber()}
            {renderDropoffCode()}
            {renderSubmitButton()}
          </Form>
        </div>
      </div>
    );
  };

  const renderPackageDetailsVal = (item:keyof PackageDetailsPayload) => {
    const packageResponse:PackageDetailsPayload = dropOffDetailsData?.consignmentDetails;
    const weightMismatchVolume = packageResponse?.weightMismatchVolume;
    if (item === 'volume') {
      return packageResponse?.length && packageResponse?.width && packageResponse?.height
        ? `${packageResponse.length}X${packageResponse.width}X${packageResponse?.height} in cms` : 'NA';
    }
    if (item === 'chargeableWeight') {
      return packageResponse?.chargeableWeight ? `${packageResponse?.chargeableWeight} Kg` : 'NA';
    }
    if (item === 'weightMismatchVolume') {
      return weightMismatchVolume?.length && weightMismatchVolume?.width
        && weightMismatchVolume?.height
        ? `${weightMismatchVolume.length}X${weightMismatchVolume.width}X${weightMismatchVolume?.height} in cms` : 'NA';
    }
    if (item === 'weightMismatchWeight') {
      return packageResponse?.weightMismatchWeight ? `${packageResponse?.weightMismatchWeight} Kg` : 'NA';
    }
    return packageResponse?.[item] ? packageResponse[item] : 'NA';
  };

  const renderOtherDetailsVal = (item: string) => {
    if (item === 'creationDetails') {
      return moment(dropOffDetailsData[item]).format('lll');
    }
    return dropOffDetailsData?.consignmentDetails[item];
  };

  const renderOtherDropOffDetails = () => {
    return (
      <div className={classes.otherDetailsMain}>
        <Row className={classes.otherDetailsRow}>
          {
            Object.keys(dropOffOtherDetailsMapping).map((item) => {
              return (
                <>
                  <Col xl={12} sm={12} xs={24} lg={12} key={item}>
                    <div className={classes.otherDetHeaders}>
                      {dropOffOtherDetailsMapping[item].name}
                    </div>
                  </Col>
                  <Col xl={12} sm={12} xs={24} lg={12}>
                    <div className={classes.otherDetVal}>
                      {renderOtherDetailsVal(item as string)}
                    </div>
                  </Col>
                </>
              );
            })
          }
        </Row>
      </div>
    );
  };

  const handleReloadDropOff = () => {
    handleDropoffSubmit({
      referencenumber: verifiedConsignmentDetail?.referenceNumber,
      validationcode: lastDropOffCode,
      isInternational: isInternationalConsignment,
    });
  };

  const renderPackageDetails = () => {
    if (!verifiedConsignmentDetail?.packingDetails?.packingMaterial?.length) {
      return null;
    }
    // Check the payment status
    const paymentStatus = verifiedConsignmentDetail?.packingDetails?.paymentStatus;
    let paymentStatusComponent;
    if (paymentStatus === 'AWAITED') {
      paymentStatusComponent = (
        <Spin spinning={loading}>
          <div className={classes.awaitPaymentDivStylePacking}>
            <span className={classes.paymentStatusStyle}>
              {paymentStatus}
              <Tooltip title="Click to refresh the status">
                <span
                  className={classes.paymentStatusRefreshButtonAwaited}
                  onClick={handleReloadDropOff}
                >
                  <RedoOutlined />
                </span>
              </Tooltip>
            </span>
          </div>
        </Spin>
      );
    } else if (paymentStatus !== 'PENDING') {
      paymentStatusComponent = (
        <Spin spinning={loading}>
          <div className={classes.sucessPaymentDivStyle}>
            <span className={classes.paymentStatusStyle}>
              SUCCESS
              <Tooltip title="Click to refresh the status">
                <span
                  className={classes.paymentStatusRefreshButtonSucess}
                  onClick={handleReloadDropOff}
                >
                  <RedoOutlined />
                </span>
              </Tooltip>
            </span>
          </div>
        </Spin>
      );
    }
    return (
      <>
        <hr className={classes.lineBreakStyle} />
        <div className={classes.packingDetails}>
          <div className={classes.packingDetailsTitleRow}>
            <div className={classes.packingDetailsTitle}>
              Packaging Material Payment
            </div>
            {paymentStatusComponent}
          </div>
          <Table
            dataSource={verifiedConsignmentDetail?.packingDetails?.packingMaterial}
            pagination={false}
            size="small"
            footer={() => (
              <>
                <div className={classes.totalCostTitle}>
                  Total Cost
                </div>
                <div className={classes.totalCostValue}>
                  {verifiedConsignmentDetail?.packingDetails?.price}
                </div>
              </>
            )}
          >
            <Column width="65%" ellipsis title="Details" dataIndex="itemDesc" key="itemDesc" />
            <Column width="15%" ellipsis title="QTY" dataIndex="purchaseQuantity" key="purchaseQuantity" />
            <Column width="20%" ellipsis title="Price/Unit" dataIndex="priceForEach" key="priceForEach" />
          </Table>
        </div>
      </>
    );
  };

  const renderPackageDropoffDetails = () => {
    return (
      <div className={classes.packageMain}>
        <div className={classes.packageDetHeading}>
          Package Details
          <div className={classes.packageFieldMain}>
            <Row className={classes.customRowStyle}>
              {
                Object.keys(dropOffPrettyNameMapping).map((item) => {
                  return (
                    <Col xl={12} sm={12} xs={24} lg={12} key={item}>
                      <Row>
                        <div className={classes.packageDetField}>
                          {dropOffPrettyNameMapping[item].name}
                        </div>
                      </Row>
                      <Row>
                        <div className={classes.packageDetVal}>
                          {renderPackageDetailsVal(item as keyof PackageDetailsPayload)}
                        </div>
                      </Row>
                    </Col>
                  );
                })
              }
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const handleClearDropoff = () => {
    setDropOffComponent(false, false, false);
    form.resetFields();
  };

  const renderOkayButton = () => {
    return (
      <Button
        className={classes.okayButton}
        type="primary"
        htmlType="submit"
        onClick={handleClearDropoff}
      >
        Okay
      </Button>
    );
  };

  const renderDetailsHeading = () => {
    return (
      <>
        <div className={classes.consignmentNumberHeading}>
          <span>
            {`CN# - ${dropOffFormData.referenceNumber}`}
          </span>
          <span style={{ marginLeft: '5px' }}>
            { `| Drop off code - ${dropOffFormData.validationCode}` }
          </span>
        </div>
        <hr className={classes.lineBreakStyle} />
      </>
    );
  };

  const renderValidDropOff = () => {
    return (
      <div className={classes.dropOffDetailsForm}>
        {renderDetailsHeading()}
        <img
          className={classes.logoSuccessStyle}
          src={dropoff_success}
          alt="Valid Dropoff"
        />
        <div className={classes.errorMessageText}>
          {dropOffMessage}
          <br />
          {refNum !== '' ? refNum : <></>}
        </div>
        {renderOkayButton()}
      </div>
    );
  };

  const handleRequestCompletion = async () => {
    if (consignmentPaymentStatus === 'PENDING') {
      setCollectCashModalOpen(true);
    } else {
      setCollectionLoading(true);
      const { referenceNumber, validationCode } = dropOffFormData;
      handleVerifyDropoff(referenceNumber as string, validationCode as string,
        true, false, isInternationalConsignment, false);
      form.resetFields();
    }
  };

  const renderCollectionButton = () => {
    const buttonClass = consignmentPaymentStatus === 'AWAITED'
      ? `${classes.collectionButton} ${classes.disabledButton}`
      : classes.collectionButton;
    if (ShowPaymentStatus === 'AWAITED') {
      return (
        <Tooltip title={collectionCompleteDiableText}>
          <div className={classes.disabledCollectionButton}>

            <span>
              Collection Complete
            </span>

          </div>
        </Tooltip>
      );
    }
    return (
      <div className={classes.collectionButtonDiv}>
        <Button
          className={buttonClass}
          onClick={handleRequestCompletion}
          type="primary"
          htmlType="submit"
          loading={collectionLoading}
          disabled={consignmentPaymentStatus === 'AWAITED'}
        >
          Collection Complete
        </Button>
      </div>
    );
  };

  const getWeightMismatchData = async (referenceNumber: string) => {
    const body = {
      consignments: [
        referenceNumber,
      ],
    };
    const res = await weightMismatchValues(body);
    setModalLoading(false);
    if (res.isSuccess) {
      const result = res.data[0];
      if (result.isEditable) {
        setIsWeightMismatchDisabled(false);
      } else {
        setIsWeightMismatchDisabled(true);
      }
      if (consignmentPaymentStatus === 'none') {
        if (result.online_payment_status === 'AWAITED') {
          SetShowPaymentStatus('AWAITED');
          setisPaymentAwaited(true);
          setPaymentLinkButtonText('Resend Payment Link');
        } else if (result.online_payment_status === 'SUCCESS' || result.online_payment_status === 'NOT REQUIRED') {
          SetShowPaymentStatus(result.online_payment_status);
        }
      }
      if (result.email) {
        setConsignmentEmail(result.email);
      }
      if (result.phone) {
        setconsignmentphone(result.phone);
      }
    } else {
      message.error(res.errorMessage || res.error.message);
      setIsWeightMismatchDisabled(false);
    }
  };

  const showModalWeightMismatch = () => {
    setIsModalOpen(true);
    setModalLoading(true);
    setVisiblePaymentStatus(true);
    const packageResponse = dropOffDetailsData?.consignmentDetails;

    const detailsForWeightmismatch = [{
      id: 1,
      referenceNumber: packageResponse?.referenceNumber,
      weight: packageResponse?.chargeableWeight,
      length: packageResponse?.length || 0,
      breadth: packageResponse?.width || 0,
      height: packageResponse?.height || 0,
    }];
    getWeightMismatchData(packageResponse?.referenceNumber as string);
    setDataSource(detailsForWeightmismatch);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setVisibleSubmitButton(false);
    if (consignmentPaymentStatus !== 'none') {
      handleReloadDropOff();
    }
  };
  const handleCommunicationModalCancel = () => {
    setIsCommunicationModal(false);
    setVisibleSubmitButton(false);
    setIsCashToOnlineDropoff(false);
  };
  const RenderWeightmismatchButton = () => {
    const buttonClass = isDropoffPaymentLinkSent
      ? `${classes.weightmismatchButton} ${classes.disabledButton}`
      : classes.weightmismatchButton;
    return (
      <FormItem>
        <Button
          className={buttonClass}
          type="primary"
          htmlType="submit"
          disabled={isDropoffPaymentLinkSent}
          onClick={showModalWeightMismatch}
        >
          Weight Mismatch
        </Button>
      </FormItem>
    );
  };

  const renderButtonPackageOption = () => {
    if (verifiedConsignmentDetail?.packingDetails?.paymentStatus === 'AWAITED'
      || verifiedConsignmentDetail?.packingDetails?.paymentStatus === 'COMPLETE') return null;
    const buttonClass = isDropoffPaymentLinkSent
      ? `${classes.weightmismatchButton} ${classes.disabledButton}`
      : classes.weightmismatchButton;
    return (
      <FormItem>
        <Button
          className={buttonClass}
          type="primary"
          htmlType="submit"
          disabled={isDropoffPaymentLinkSent}
          onClick={() => setIsPackageModalOpen(true)}
        >
          Packaging Material
        </Button>
      </FormItem>
    );
  };

  const onEditWeightMismatch = (record: any) => {
    setIsEditing(true);
    seteditingWeightmismatch({ ...record });
  };

  const resetWeightmismatchEditing = () => {
    setIsEditing(false);
    seteditingWeightmismatch(null);
    setVisibleSubmitButton(true);
  };

  const cancelDimensionsModal = () => {
    setIsEditing(false);
    seteditingWeightmismatch(null);
  };

  const handelWeightmismatch = async (values: any) => {
    const body = {
      consignments: [
        {
          reference_number: values.referencenumber as string,
          weight: values.weight as string,
          height: values.height as string,
          length: values.length as string,
          width: values.width as string,
        },
      ],
    };
    const res = await getWeightMismatchSubmit(body);
    setModalLoading(false);

    if (res.isSuccess) {
      const result = res.data;
      if (result.amount) {
        setDifferentialCost(result.amount);
      }
      if (result.proceedBooking && consignmentPaymentStatus === 'none') { // todo recheck someone
        setShowDifferntialCost(false);
        SetShowPaymentStatus('NOT REQUIRED');
        setisPaymentAwaited(false);
      } else {
        if (consignmentPaymentStatus === 'none') {
          SetShowPaymentStatus('AWAITED');
          setVisiblePaymentStatus(false);
          setPaymentLinkButtonText('Send Payment Link');
          setisPaymentAwaited(true);
        }
        setShowDifferntialCost(true);
      }
    } else {
      message.error(res.errorMessage || res.error.message);
    }
  };

  const handelWeightmismatchSubmit = () => {
    setModalLoading(true);
    const values = {
      referencenumber: dataSource[0]?.referenceNumber,
      length: dataSource[0]?.length,
      width: dataSource[0]?.breadth,
      height: dataSource[0]?.height,
      weight: dataSource[0]?.weight * 1000,
    };
    handelWeightmismatch(values);
  };

  const sendPaymentLink = async (refNo: string, email: string, phone: string) => {
    const body = {
      consignments: [
        refNo,
      ],
      email,
      phone,
    };

    const res = await weightMismatchPaymentLink(body);
    setModalLoading(false);
    if (res.isSuccess) {
      const result = res.data;
      if (result.proceedBooking) {
        SetShowPaymentStatus('SUCCESS');
      } else {
        SetShowPaymentStatus('AWAITED');
      }
      message.success('Payment Link has been sent');
      handleModalCancel();
      setPaymentLinkButtonText('Resend Payment Link');
      setShowDifferntialCost(false);
    } else {
      message.error(res.errorMessage || res.error.message);
    }
  };
  const handleSendpaymentLinkWeightMismatch = () => {
    setcommunicationModalTitle('WEIGHT MISMATCH');
    setModalLoading(true);
    setIsCommunicationModal(true);
    setIsModalOpen(false);
  };

  const handleSendpaymentLinkOnlineDropoff = () => {
    setcommunicationModalTitle('ONLINE PAYMENT DETAILS');
    setIsCashToOnlineDropoff(true);
    setIsCommunicationModal(true);
    setIsModalOpen(false);
  };

  const handleSendCommunicationDetails = () => {
    setModalLoading(true);
    const referencenumber = dataSource[0]?.referenceNumber;
    const email = consignmentEmail;
    const phone = consignmentphone;
    sendPaymentLink(referencenumber as string, email as string, phone as string);
  };

  const handleSendCommunicationDetailsOnlineDropoff = () => {
    setCollectionLoading(true);
    setIsCommunicationModal(false);
    setCollectCashModalOpen(false);
    const { referenceNumber, validationCode } = dropOffFormData;
    handleVerifyDropoff(referenceNumber as string, validationCode as string, false, false,
      isInternationalConsignment, true);

    form.resetFields();
  };

  const RenderModal = () => {
    const actions = [
      {
        key: '7',
        title: 'ACTIONS',
        hidden: isWeightMismatchDisabled,
        render: (record:any) => {
          return (
            <>
              <EditOutlined onClick={() => {
                onEditWeightMismatch(record);
              }}
              />
            </>
          );
        },

      },
    ].filter((item) => !item.hidden);

    const weightMismatchColumnsWithAction = [
      ...weightMismatchColumns,
      ...actions,
    ];

    const exceptThisSymbols = ['e', 'E', '+', '-'];

    return (
      <Modal
        title="WEIGHT MISMATCH"
        open={isModalOpen}
        footer={null}
        width={1000}
        onCancel={handleModalCancel}
        className={classes.modal}
      >
        <div>
          <Table
            columns={weightMismatchColumnsWithAction}
            dataSource={dataSource}
            pagination={false}
            loading={modalLoading}
            bordered
          />
        </div>
        <div className={classes.modalButtons}>
          {
            ShowDifferntialCost
              ? (
                <label className={classes.differentailCost}>
                  {'Differential Cost: '}
                  {differentialCost || 0}
                </label>
              ) : null
          }
          {
            ShowPaymentStatus !== 'none' && visiblePaymentStatus
              ? (
                <label className={classes.weightmismatchPaymentStatus}>
                  {'Current Payment Status: '}
                  {ShowPaymentStatus}
                </label>
              ) : null
          }
          {
            isPaymentAwaited
              ? (
                <Button
                  className={classes.weightmismatchSubmitButton}
                  onClick={handleSendpaymentLinkWeightMismatch}
                >
                  {paymentLinkButtonText}
                </Button>
              ) : null
          }
          {
            visibleSubmitButton
              ? (
                <Button
                  className={classes.weightmismatchSubmitButton}
                  onClick={handelWeightmismatchSubmit}
                  style={{
                    width: '180px',
                    padding: '3px',
                  }}
                >
                  SHOW DIFFERENTIAL COST
                </Button>
              ) : null
          }
          {
            consignmentPaymentStatus !== 'none'
              ? (
                <Button
                  className={classes.weightmismatchSubmitButton}
                  onClick={handleModalCancel}
                >
                  CONFIRM
                </Button>
              ) : null
          }
        </div>
        <Modal
          title="EDIT WEIGHT AND DIMENSIONS"
          visible={isEditing}
          onCancel={() => {
            cancelDimensionsModal();
          }}
          onOk={() => {
            setDataSource((prev: any) => {
              return prev.map((element: any) => {
                if (element.id === editingWightmmismatch.id) {
                  return editingWightmmismatch;
                }
                return element;
              });
            });
            resetWeightmismatchEditing();
          }}
        >
          <label>WEIGHT(KG)</label>
          <Input
            value={editingWightmmismatch?.weight}
            type="number"
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            onChange={(element) => {
              seteditingWeightmismatch((prev: any) => {
                return { ...prev, weight: element.target.value };
              });
            }}
          />
          <label>LENGTH(CM)</label>
          <Input
            value={editingWightmmismatch?.length}
            type="number"
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            onChange={(element) => {
              seteditingWeightmismatch((prev: any) => {
                return { ...prev, length: element.target.value };
              });
            }}
          />
          <label>BREADTH(CM)</label>
          <Input
            value={editingWightmmismatch?.breadth}
            type="number"
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            onChange={(element) => {
              seteditingWeightmismatch((prev: any) => {
                return { ...prev, breadth: element.target.value };
              });
            }}
          />
          <label>HEIGHT(CM)</label>
          <Input
            value={editingWightmmismatch?.height}
            type="number"
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            onChange={(element) => {
              seteditingWeightmismatch((prev: any) => {
                return { ...prev, height: element.target.value };
              });
            }}
          />

        </Modal>
      </Modal>
    );
  };

  const handleCollectCash = () => {
    setCollectCashModalOpen(false);
    setCollectionLoading(true);
    const { referenceNumber, validationCode } = dropOffFormData;
    handleVerifyDropoff(referenceNumber as string, validationCode as string,
      true, true, isInternationalConsignment, false);
    form.resetFields();
  };

  const handleCashCollectionCancel = () => {
    setCollectCashModalOpen(false);
  };
  const RenderCashCollectionModal = () => {
    const packingMessage = (packagingAmount > 0);
    const weightMismatchMessage = (differentialCost > 0);
    const showOnlinePaymentButton = window.localStorage.getItem('allowCashPaymentForDropOff');

    // Dialog to show when collecting cash
    return (
      <Modal
        title="Payment"
        open={isCollectCashModalOpen}
        footer={null}
        width={400}
        onCancel={handleCashCollectionCancel}
        className={classes.cashModal}
      >
        <Alert
          message={
            (
              <>
                <div className={classes.line}>
                  <span>Tariff Amount</span>
                  <span>{`Rs. ${tariffCharges}`}</span>
                </div>
                {weightMismatchMessage && (
                  <div className={classes.line}>
                    <span>Weight Mismatch Charges</span>
                    <span>
                      {`Rs. ${differentialCost}`}
                    </span>
                  </div>
                )}
                {packingMessage && (
                  <div className={classes.line}>
                    <span>Packaging Material Charges</span>
                    <span>
                      {`Rs. ${packagingAmount}`}
                    </span>
                  </div>
                )}
                <div className={classes.pendingAmount}>
                  <span>Amount to be collected</span>
                  <span style={{ fontWeight: 'bold' }}>{`Rs. ${amountPending}`}</span>
                </div>
              </>
            )
          }
          type="success"
        />
        <div className={classes.modalButtons}>
          {
            consignmentPaymentStatus === 'PENDING' && showOnlinePaymentButton
              ? (
                <Button
                  className={classes.submitPaymentLinkButton}
                  onClick={handleSendpaymentLinkOnlineDropoff}
                >
                  Send Payment Link
                </Button>
              ) : null
          }

          <Button
            className={classes.collectCashSubmitButton}
            onClick={handleCollectCash}
          >
            Collect Cash
          </Button>
        </div>
      </Modal>
    );
  };


  const handlePhoneNoChange = (e: any) => {
    const inputValue = e.target.value;
    setconsignmentphone(inputValue);

    const regex = /^(?!.*(\d)\1{9})(0[6-9]\d{9}|(?:(\+91)?[6-9]\d{9})|(?:(91)?[6-9]\d{9}))$/;
    const isValidNumber = regex.test(inputValue);
    setValidPhone(isValidNumber);
  };

  const handleEmailChange = (e: any) => {
    const inputValue = e.target.value;
    setConsignmentEmail(inputValue);

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmailValue = regex.test(inputValue);
    setValidEmail(isValidEmailValue);
  };

  const disableButton = () => {
    return (!isValidPhone || !isValidEmail);
  };

  const RenderContactDetailsModal = () => {
    const disable = disableButton();
    return (
      <Modal
        title={communicationModalTitle}
        open={isCommunicationModal}
        footer={null}
        width={1000}
        onCancel={handleCommunicationModalCancel}
        className={classes.modal}
      >
        <div>
          <div className={classes.communicationModalFields}>
            <label>Phone</label>
            <div className={classes.coummunicationModalErrHandling}>
              <Input
                className={classes.communicationModalInputFields}
                value={consignmentphone}
                type="tel"
                onChange={(e) => handlePhoneNoChange(e)}
              />
              {isValidPhone ? null : <p>Invalid phone number</p>}
            </div>
          </div>
          <div className={classes.communicationEmailField}>
            <label>EMAIL</label>
            <div className={classes.coummunicationModalErrHandling}>
              <Input
                className={classes.communicationModalInputFields}
                value={consignmentEmail}
                type="email"
                onChange={(e) => handleEmailChange(e)}
              />
              {isValidEmail ? null : <p>Invalid Email Id</p>}
            </div>
          </div>
        </div>
        <div className={classes.modalButtons}>
          {
            isCashToOnlineDropoff
              ? (
                <Button
                  className={classes.weightmismatchSubmitButton}
                  onClick={handleSendCommunicationDetailsOnlineDropoff}
                  disabled={disable}
                >
                  Send Payment Link
                </Button>
              ) : null
          }
          {
            isPaymentAwaited
              ? (
                <Button
                  className={classes.weightmismatchSubmitButton}
                  onClick={handleSendCommunicationDetails}
                  disabled={disable}
                >
                  {paymentLinkButtonText}
                </Button>
              ) : null
          }
        </div>
      </Modal>
    );
  };

  const paymentRefresh = () => {
    setModalLoading(true);
    const packageResponse = dropOffDetailsData?.consignmentDetails;
    getWeightMismatchData(packageResponse?.referenceNumber as string);
  };

  const renderDropOffDetails = () => {
    return (
      <div className={classes.dropOffDetailsForm}>
        {
          ShowPaymentStatus !== 'none'
            ? (
              <div className={classes.consignmentNumberPaymentStatus}>
                <div className={classes.dropOffDetailsWithPaymentStatus}>
                  <div>{`CN# - ${dropOffFormData.referenceNumber}`}</div>
                  {consignmentPaymentStatus === 'PENDING' ? (
                    <Spin spinning={modalLoading}>
                      <div className={classes.pendingPaymentDivStyle}>
                        <span
                          className={classes.pendingPaymentStatusStyle}
                        >
                          Payment Pending - Rs.
                          {amountPending}
                        </span>
                      </div>
                    </Spin>
                  ) : (<></>)}
                  {
                    consignmentPaymentStatus === 'AWAITED'
                      ? (
                        <>
                          <Spin spinning={modalLoading}>
                            <div className={classes.awaitConsignmentPaymentDivStyle}>
                              <span className={classes.paymentStatusStyle}>
                                Payment Pending - Rs.
                                {amountPending}
                                <Tooltip title="Click to refresh the status">
                                  <span
                                    className={classes.paymentStatusRefreshButtonAwaited}
                                    onClick={handleReloadDropOff}
                                  >
                                    <RedoOutlined />
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </Spin>
                        </>
                      ) : <></>
                  }
                  {ShowPaymentStatus !== 'PENDING' || consignmentPaymentStatus === 'SUCCESS'
                    ? (
                      <div className={classes.paymentDivStyle}>
                        <span className={classes.paymentFontStyle}>Paid Online</span>
                      </div>
                    ) : <></>}
                </div>
                <div className={classes.dropOffDetailsWithPaymentStatus}>
                  {
                    ShowPaymentStatus === 'AWAITED'
                      ? (
                        <>
                          <span>Weight Mismatch Payment</span>
                          <Spin spinning={modalLoading}>
                            <div className={classes.awaitPaymentDivStyle}>
                              <span className={classes.paymentStatusStyle}>
                                AWAITED
                                <Tooltip title="Click to refresh the status">
                                  <span
                                    className={classes.paymentStatusRefreshButtonAwaited}
                                    onClick={paymentRefresh}
                                  >
                                    <RedoOutlined />
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </Spin>
                        </>
                      ) : <></>
                      }
                  {ShowPaymentStatus === 'SUCCESS' ? (
                    <Spin spinning={modalLoading}>
                      <div className={classes.sucessPaymentDivStyle}>
                        <span
                          className={classes.paymentStatusStyle}
                        >
                          SUCCESS
                          <Tooltip title="Click to refresh the status">
                            <span
                              className={classes.paymentStatusRefreshButtonSucess}
                              onClick={paymentRefresh}
                            >
                              <RedoOutlined />
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                    </Spin>
                  ) : (<></>)}
                </div>
              </div>
            ) : (
              <div className={classes.consignmentNumberHeading}>
                <div className={classes.dropOffDetailsMain}>
                  <div>{`CN# - ${dropOffFormData.referenceNumber}`}</div>
                  <div className={classes.paymentDivStyle}>
                    <span className={classes.paymentFontStyle}>Paid Online</span>
                  </div>
                </div>
              </div>
            )
        }

        <hr className={classes.lineBreakStyle} />
        {renderPackageDropoffDetails()}
        <hr className={classes.lineBreakStyle} />
        {renderOtherDropOffDetails()}
        {renderPackageDetails()}
        {isPackageModalOpen
          ? (
            <PackageOption
              isPackageModalOpen={isPackageModalOpen}
              setIsPackageModalOpen={setIsPackageModalOpen}
              referenceNumber={dropOffFormData?.referenceNumber}
              verifiedConsignmentDetail={verifiedConsignmentDetail}
              handleReloadDropOff={handleReloadDropOff}
              showPaymentStatus={ShowPaymentStatus}
              isInternational={isInternationalConsignment}
            />
          )
          : null}
        {RenderWeightmismatchButton()}
        {renderButtonPackageOption()}
        {renderCollectionButton()}
        {RenderModal()}
        {RenderCashCollectionModal()}
        {RenderContactDetailsModal()}
      </div>
    );
  };

  const renderInvalidDropoff = () => {
    return (
      <div className={classes.dropOffDetailsForm}>
        {renderDetailsHeading()}
        <img
          className={classes.logoStyle}
          src={dropoff_invalid}
          alt="Invalid Dropoff"
        />
        <div className={classes.errorMessageText}>
          {dropOffMessage || 'Invalid Dropoff'}
        </div>
        {renderOkayButton()}
      </div>
    );
  };

  const renderTabs = () => {
    const tabs = [
      { name: 'Upcoming', Component: <TabularDropoff status="UPCOMING" /> },
      { name: 'Completed', Component: <TabularDropoff status="COMPLETED" /> },
      { name: 'Rejected', Component: <TabularDropoff status="REJECTED" /> },
    ];
    return tabs;
  };

  const renderDropoffRequestMain = () => {
    return (
      <div className={classes.dropOffMain}>
        <div className={classes.leftPart}>
          {renderDropOffForm()}
          {showInvalidDropoff && renderInvalidDropoff()}
          {showValidDropoff && renderValidDropOff()}
          {showDropoffDetails && renderDropOffDetails()}
        </div>
        <div className={classes.rightPart}>
          <CustomTabs tabs={renderTabs()} destroyInactiveTabPane />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.contentDiv}>
      {renderDropoffRequestMain()}
    </div>
  );
};

const hocConfig: HocOptions = {
  connectRedux: {
    useRedux: true,
  },
  connectJss: {
    useJss: true,
    styleSheet: dropoffStyles,
  },
  connectRouter: true,
};

export default GenericHoc(hocConfig)(DropoffPage);
